<template>
 <div>
   <div class="all-height d-flex justify-center">
     <div class="all-width">
       <v-card class="" loader-height="2" :loading="loading" :disabled="loading">
         <v-card-text>
           <div class="d-flex align-center mb-2">
             <div class="mr-2" v-if="metadata._id">
               <v-chip v-if="stage === 3" small label color="success">Closed</v-chip>
               <v-chip v-else-if="stage === 1" small label color="warning">Pending for review</v-chip>
               <v-chip v-else-if="stage === 0" small label color="info">Open</v-chip>
             </div>
             <v-btn class="shadow-off" text @click.stop="$nova.gotoLink({path: backlink})" icon>
               <v-icon>mdi-arrow-left</v-icon>
             </v-btn>
             <v-btn class="shadow-off" small icon @click="refreshData()">
               <v-icon>mdi-refresh</v-icon>
             </v-btn>
             <v-spacer></v-spacer>
           </div>
           <div class="d-flex align-center mb-2" v-if="metadata._id">
             <div>
               <div class="d-flex align-center">
                 <div class="subtitle-1 font-weight-bold mr-2">
                   {{ metadata.description }}
                 </div>
               </div>
               <div>
                 <span class="mr-2">
                   <v-icon class="mr-1">mdi-calendar-range</v-icon> Period:
                 </span>
                 <span class="mr-1">{{ $nova.formatDate(metadata.from) }}</span>
                 <span class="mr-1">- {{ $nova.formatDate(metadata.to) }}</span>
               </div>
               <div class="mt-1 body-2"> Analysis : {{alaysisDetails.name || ""}} <span class="ml-1"> Start : {{ $nova.formatDate(alaysisDetails.startdate)}} </span> <span class="ml-1"> End :{{$nova.formatDate(alaysisDetails.enddate)}}</span></div>
             </div>
             <v-spacer></v-spacer>
           </div>
           <div v-if="loading && (!metadata._id || !botdetails._id)">
             <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
           </div>
           <div class="py-8" v-else-if="!botdetails._id">
             <div class="body-1 mb-4">No Bot details found</div>
             <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
           </div>
           <div v-else-if="metadata.stage === 3 && Object.keys(rundetails).length === 0" class="pa-8 title text-center grey--text"> Bot not run </div>
           <div v-else>
             <div v-if="metadata.stage !== 3">
               <v-alert v-if="!savedParameters && hasParameters" type="info" text dense border="left">Save the Rules to proceed</v-alert>
               <v-alert v-else-if="!savedFiles && hasFiles" type="info" text dense border="left">Upload all the required files to start the analysis</v-alert>
             </div>
             <div v-if="(processedFiles || !hasFiles) && (savedParameters || !hasParameters) && metadata.stage !== 3" class="d-flex">
               <v-spacer></v-spacer>
			<v-menu
                open-on-hover
                transition="scale-transition"
                elevation-0
                max-width="300"
                offset-y
				v-if="stingingcheck !== 1"
                >
                 <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-10" text icon color="lighten-2"  v-bind="attrs"
                    v-on="on">
                  <v-icon>mdi-information</v-icon>
                </v-btn>
                 </template>
                 <v-card>
                  <v-card-text class="text-6 font-weight-bold">
                    {{stageMassage}}
                  </v-card-text>
                </v-card>
              </v-menu>

               <v-btn color="fbcPrimary" v-if="Object.keys(rundetails).length === 0" class="ml-2 shadow-off white--text" small @click="startRun()">
                 <v-icon class="mr-1">mdi-play</v-icon> Start Run
               </v-btn>
               <v-btn color="fbcPrimary" v-else class="ml-2 shadow-off white--text" small @click="startRun()">
                 <v-icon class="mr-1">mdi-play</v-icon> Re Run
               </v-btn>
			<v-btn color="fbcPrimary" v-if="stingingcheck === 1" class="ml-2 shadow-off white--text" small>
                Results available
               </v-btn>
			<v-btn color="error" v-else-if="stingingcheck === 2" class="ml-2 shadow-off white--text" small>
                Transaction Matching execution Failed 
               </v-btn>
			<v-btn color="info" v-else-if="stingingcheck === 4" class="ml-2 shadow-off white--text" small>
                In Progress
               </v-btn>
			<v-btn color="info" v-else-if="stingingcheck === 0" class="ml-2 shadow-off white--text" small>
                In Progress
               </v-btn>
             </div>
             <v-stepper v-model="e1" class="mt-2" elevation="0" non-linear>
               <v-stepper-header>
                 <v-stepper-step editable step="1" color="success">Input Data</v-stepper-step>
                 <v-divider></v-divider>
                 <v-stepper-step editable step="2" color="success">Apply Filter</v-stepper-step>
                 <v-divider></v-divider>
                 <v-stepper-step step="3" editable color="success" @click="addfilterConfimation()">Mapping Masters</v-stepper-step>
                 <v-divider></v-divider>
                 <v-stepper-step step="4" editable color="success">Matching Routines</v-stepper-step>
                 <v-divider></v-divider>
                 <v-stepper-step step="5" editable color="success" @click="annexergenerateAll(alaysisDetails.filecount,selectedresulttab,resultTab = 0);addrulesConfimation()"> Results </v-stepper-step>
                 <v-divider></v-divider>
				<v-stepper-step step="6" editable color="success" v-if="fifoalaysis" @click="fifiCollection()"> FIFO </v-stepper-step>
                 <v-divider v-if="fifoalaysis"></v-divider>
                 <v-stepper-step :step="fifoalaysis ? 7 : 6" editable color="success" @click="recoinSummary()">Reconciliation</v-stepper-step>
               </v-stepper-header>
               <v-stepper-items>
                 <v-stepper-content step="1" class="shadow-off pt-2 pb-0 pl-0 pr-0">
                   <FileUploadsOwn :files="setupList.file" :filevalues="uploaddetails" :editable="true" :storepath="'/v2/bot/analysis/storefile/' + this.alaysisDetails.bpid" :fileproerr="fileprocesserrors" :fileprostage="fileprocessstage" :fileuppercent="fileuploadpercent" :filesdataPros="filesdata" :fileconfig="fileConfigInfo" @startloading="loading = true" @stoploading="loading = false" @storedData="refreshData()" :fileuploadeCount="alaysisDetails.filecount" :botId="this.alaysisDetails.bpid"/>
                   <div class="mt-4">
                     <v-btn small color="fbcPrimary" @click="e1 = 2" class="white--text mr-1">Continue</v-btn>
                   </div>
                 </v-stepper-content>
                 <v-stepper-content step="2" class="shadow-off pt-2 pb-0 pl-0 pr-0">
                   <div>
                     <div class="d-flex align-center mb-4 mt-4">
                       <v-btn small color="fbcPrimary" class="white--text" @click="addfilterbox()">
                         <v-icon class="mr-1">mdi-plus</v-icon>
                         <span>Create Filter</span>
                       </v-btn>
                       <v-spacer></v-spacer>
                       <v-btn small color="fbcPrimary" class="white--text" @click="addfilter()" v-if="formatedData.length >0">
                         <span>Save</span>
                       </v-btn>
                     </div>
                     <v-card class="shadow-off">
                       <v-card-text class="pt-0 pb-0 shadow-off" v-if="formatedData.length >0">
                         <v-simple-table dense class="FC-Table">
                           <template v-slot:default>
                             <thead>
                               <tr>
                                 <th v-for="(header, index) in headers" :key="index"  :id="'automation-test-'+ index">
                                   {{header.text }}
                                 </th>
                               </tr>
                             </thead>
                             <tbody>
                               <tr v-for="(item, i) in formatedData" :id="'automation-test-'+ i" :key="i" draggable="true" @dragstart="onDragStart(i)" @dragover.prevent @drop="onDropFilter(i)" @dragend="onDragEnd">
                                 <td>{{ item.indexno + 1 }}</td>
                                 <td>{{ item.tag }}</td>
                                 <td>{{ item.description }}</td>
                                 <td>{{ item.operator }}</td>
                                 <td>{{ gettableName(item.tableid) }}</td>
                                 <td>{{ item.column }}</td>
                                 <td>{{ item.filterword }}</td>
                                 <td>
                                   <div class="d-flex">
									<v-tooltip bottom content-class="tooltip-bottom">
                                       <template v-slot:activator="{ on, attrs }">
                                         <v-btn icon small v-bind="attrs" v-on="on" class="cursor-drag"> 
                                           <v-icon> mdi-drag </v-icon>
                                         </v-btn>
                                       </template>
                                       <span>Drag-Drop</span>
                                     </v-tooltip>
                                     <v-tooltip bottom content-class="tooltip-bottom">
                                       <template v-slot:activator="{ on, attrs }">
                                         <v-btn icon small v-bind="attrs" v-on="on" @click.stop="editfilterboxPush(item)">
                                           <v-icon>mdi-pencil</v-icon>
                                         </v-btn>
                                       </template>
                                       <span>Edit</span>
                                     </v-tooltip>
                                     <v-tooltip bottom content-class="tooltip-bottom">
                                       <template v-slot:activator="{ on, attrs }">
                                         <v-btn icon small v-bind="attrs" v-on="on" @click.stop="deletefilterboxPush(item)">
                                           <v-icon>mdi-delete</v-icon>
                                         </v-btn>
                                       </template>
                                       <span>Delete</span>
                                     </v-tooltip>
                                   </div>
                                 </td>
                               </tr>
                             </tbody>
                           </template>
                         </v-simple-table>
                       </v-card-text>
                       <v-card-text v-else>
                         <div class="text-center grey--text title pa-6"> No Filter available </div>
                       </v-card-text>
                     </v-card>
                   </div>
                   <div class="mt-4">
                     <v-btn small color="fbcPrimary" @click="addfilterConfimation()" class="mr-1 white--text">Continue</v-btn>
                     <v-btn small color="fbcPrimary" class="white--text" @click="e1 = 1">Back</v-btn>
                   </div>
                 </v-stepper-content>
                 <v-stepper-content step="3" class="shadow-off pt-2 pb-0 pl-0 pr-0">
                   <div>
                     <v-card class="shadow-off">
                       <div class="d-flex align-center mb-4 mt-4">
                         <v-btn small color="fbcPrimary" class="white--text" @click="addMiddlewarebox()">
                           <v-icon class="mr-1">mdi-plus</v-icon>
                           <span>Middleware</span>
                         </v-btn>
                       </div>
                       <v-card-text class="pt-0 pb-0" v-if="formatedDataMiddeleware.length > 0">
                         <lb-datatable :headers="middlewareHeader" :bgColor="true" :items="formatedDataMiddeleware" :enableslot="['middlewarename','basefile','action']" :loading="loading">
                           <template v-slot:middlewarename="{ item }">
                             <div @click="$nova.downloadFile((item.middlewarefile))" class="mx-2 cursor-pointer blue--text text--darken-2 caption">
                               {{item.middlewarename}}
                             </div>
                           </template>
                           <template v-slot:basefile="{ item }">
                             {{gettableName(item.basefile)}}
                           </template>
                           <template v-slot:action="{ item }">
                             <div class="d-flex">
								<v-tooltip bottom content-class="tooltip-bottom">
                                 <template v-slot:activator="{ on, attrs }">
                                   <v-btn icon small v-bind="attrs" v-on="on" @click.stop="editmiddlewarebox(item)" class="mt-4">
                                     <v-icon>mdi-pencil</v-icon>
                                   </v-btn>
                                 </template>
                                 <span>Edit</span>
                               </v-tooltip>

								<v-switch dense hide-details
								v-model="item.status" @change="changeMid(item._id,item.status)"  color="success">
								<template v-slot:label>
									<div class="ml-2" v-if="item.status">
									Enable 
									</div>
									<div class="ml-2" v-else>
									Disable
									</div>
								</template>
								></v-switch>
                               
                             </div>
                           </template>
                         </lb-datatable>
                       </v-card-text>
                       <div v-else class="text-center grey--text title pa-6"> No data available </div>
                     </v-card>
                   </div>
                   <div class="mt-4">
                     <v-btn small color="fbcPrimary" @click="e1 = 4" class="white--text mr-1">Continue</v-btn>
                     <v-btn small color="fbcPrimary" @click="e1 = 2" class="white--text">Back</v-btn>
                   </div>
                 </v-stepper-content>
                 <v-stepper-content step="4" class="shadow-off pt-2 pb-0 pl-0 pr-0">
                   <v-row>
                     <v-col cols="12" class="pb-0">
                       <div class="d-flex mt-2">
                         <v-btn small class="mb-2 mr-2 white--text" color="fbcPrimary" @click="fileMapshow = !fileMapshow;"> File Mapping Info</v-btn>
                         <v-spacer></v-spacer>
                         <v-btn small class="mb-2 mr-2 white--text" color="fbcPrimary" @click="saveInputParameters()" v-if="rulesdata.length > 0">Save Rules</v-btn>
                         <v-btn small color="fbcPrimary" class="white--text" @click="openAddEditRuleDialog()">

                           <v-icon class="mr-1">mdi-plus</v-icon>Rule
                         </v-btn>
                       </div>
                     </v-col>
                     <v-col cols="5" class="pt-0" v-if="fileMapshow">
                       <div class="body-2 font-weight-bold mt-4"> Column Mapping </div>
                       <div class="mt-2">
                         <v-simple-table dense class="FC-Table">
                           <thead>
                             <tr>
                               <th :style="`background-color:${colors[1]} !important`" scope="d1cols" class="text-center">
                                 {{ filenames[0] }}
                               </th>
                               <th scope="dtypes" class="text-center white"> Data Type </th>
                               <th :style="`background-color:${colors[2]} !important`" scope="d2cols" class="text-center">
                                 {{ filenames[1] }}
                               </th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr v-for="(v, k) in columns" :key="k" :id="'automation-test-'+ k">
                               <td class="text-center">{{ v.data1 }}</td>
                               <td class="text-center">
                                 <v-icon class="mr-4">mdi-minus</v-icon>
                                 <v-chip small label>{{ v.type }}</v-chip>
                                 <v-icon class="ml-4">mdi-minus</v-icon>
                               </td>
                               <td class="text-center">{{ v.data2 }}</td>
                             </tr>
                           </tbody>
                         </v-simple-table>
                       </div>
                     </v-col>
                     <v-col cols="7" class="pt-0">
                       <div class="body-2 font-weight-bold mt-4">Rules</div>
                       <div v-if="hasValidData(rulesdata)">
                         <div class="mt-2 d-flex " v-for="(v, k) in rulesdata" :key="'m' + k" :id="'automation-test-'+ k" draggable="true" @dragstart="onDragStart(k)" @dragover.prevent @drop="onDroprule(k)" @dragend="onDragEnd">
                           <v-avatar size="35" color="grey lighten-2" class="font-weight-bold" outlined>{{ k + 1 }}</v-avatar>
                           <div class="border-on mx-2 pa-4 flex-grow-1 radius-on cursor-move hoverable">
                             <div class="d-flex">
                               <v-chip label small class="mr-1" color="success" v-if="v && v.nature === 'Automated'">Automated</v-chip>
                               <v-chip label small class="mr-1" color="info" v-else-if="v && v.nature === 'Suggested'">Suggested</v-chip>
                               <v-chip label small v-if="v && v.type === '1:1'">One-to-One</v-chip>
                               <v-chip label small v-else-if="v && v.type === '1:M'">One-to-Many</v-chip>
                               <v-chip label small v-else-if="v && v.type === 'M:1'">Many-to-One</v-chip>
                               <v-chip label small v-else-if="v && v.type === 'M:M'">Many-to-Many</v-chip>
                               <v-spacer></v-spacer>
                               <v-btn icon small @click="openAddEditRuleDialog(v, 'edit', k)">
                                 <v-icon>mdi-cog</v-icon>
                               </v-btn>
                               <v-btn icon small @click="rulesdata.splice(k,1)">
                                 <v-icon>mdi-close</v-icon>
                               </v-btn>
                             </div>
                             <div class="my-2">
                               <div class="mb-0" v-for="(vv, kk) in v && v.cols" :key="kk" :id="'automation-test-'+ kk">
                                 <div v-if="vv.type === 'exact'" class="d-flex align-start">
                                   <v-icon class="mt-1">mdi-circle-small</v-icon>
                                   <div>
                                     <v-chip x-small label :style="`background-color:${colors[1]} !important`" class="px-2">{{ columns[kk] && columns[kk].data1 }}</v-chip>
                                     <span class="mx-2">exactly matches</span>
                                     <v-chip x-small label :style="`background-color:${colors[2]} !important`" class="px-2">{{ columns[kk] && columns[kk].data2 }}</v-chip>
                                   </div>
                                 </div>
                                 <div v-if="vv.type === 'contains'">
                                   <div v-if="vv.in === 'second'" class="d-flex align-start">
                                     <v-icon class="mt-1">mdi-circle-small</v-icon>
                                     <div>
                                       <v-chip x-small label :style="`background-color:${colors[1]} !important`" class="px-2">{{ columns[kk].data1 }}</v-chip>
                                       <span class="mx-2">contains in</span>
                                       <v-chip x-small label :style="`background-color:${colors[2]} !important`" class="px-2">{{ columns[kk].data2 }}</v-chip>
                                     </div>
                                   </div>
                                   <div v-else-if="vv.in === 'first'" class="d-flex align-start">
                                     <v-icon class="mt-1">mdi-circle-small</v-icon>
                                     <div>
                                       <v-chip x-small label :style="`background-color:${colors[2]} !important`" class="px-2">{{ columns[kk].data2 }}</v-chip>
                                       <span class="mx-2">contains in</span>
                                       <v-chip x-small label :style="`background-color:${colors[1]} !important`" class="px-2">{{ columns[kk].data1 }}</v-chip>
                                     </div>
                                   </div>
                                 </div>
                                 <div v-if="typeof vv.type === 'number'" class="d-flex align-start">
                                   <v-icon class="mt-1">mdi-circle-small</v-icon>
                                   <div>
                                     <v-chip x-small label :style="`background-color:${colors[1]} !important`" class="px-2">{{ columns[kk].data1 }}</v-chip>
                                     <span class="mx-2">and</span>
                                     <v-chip x-small label :style="`background-color:${colors[2]} !important`" class="px-2">{{ columns[kk].data2 }}</v-chip>
                                     <span class="mx-2" v-if="columns[kk].type === 'number'">has maximum {{ $nova.formatNumber(vv.type) }} differance between eachother</span>
                                     <span class="mx-2" v-else-if="columns[kk].type === 'date'">has maximum {{ vv.type }} days differance between eachother</span>
                                   </div>
                                 </div>
                               </div>
                               <div class="mb-0 d-flex align-start" v-if="v && v.group_by">
                                 <v-icon class="mt-1">mdi-circle-small</v-icon>
                                 <div>
                                   <span class="mr-2">Group by columns</span>
                                   <template v-if="v.type === 'M:1' || v.type === 'M:M'">
                                     <v-chip v-for="(vv, kk) in v.group_by" :id="'automation-test-'+ kk" x-small label :style="`background-color:${colors[1]} !important`" class="px-2 mr-1" :key="'1' + kk">{{ columns[vv].data1 }}</v-chip>
                                   </template>
                                   <span class="mx-2" v-if="v.type === 'M:M'">and</span>
                                   <template v-if="v.type === '1:M' || v.type === 'M:M'">
                                     <v-chip v-for="(vv, kk) in v.group_by" x-small label :id="'automation-test-'+ kk" :style="`background-color:${colors[2]} !important`" class="px-2 mr-1" :key="'2' + kk">{{ columns[vv].data2 }}</v-chip>
                                   </template>
                                   <span class="ml-2" v-if="(v.agg_cols || []).length > 0">by applying</span>
                                   <template v-for="(vv, kk) in v.agg_cols || []" >
                                     <div class="d-inline-block" :key="'3' + kk" :id="'automation-test-'+ kk">
                                       <span class="mx-2">{{ v.agg_type[kk] }} on</span>
                                       <v-chip x-small label :style="`background-color:${colors[1]} !important`" class="px-2">{{ columns[vv].data1 }}</v-chip>
                                       <span class="mx-2">and</span>
                                       <v-chip x-small label :style="`background-color:${colors[2]} !important`" class="px-2">{{ columns[vv].data2 }}</v-chip>
                                       <span v-if="(v.agg_cols.length - kk) > 1">,</span>
                                     </div>
                                   </template>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                       <div v-else class="pa-8 grey--text title text-center"> No rules added </div>
                     </v-col>
                   </v-row>
                   <div class="mt-4">
                     <v-btn small color="fbcPrimary" @click="e1 = 5" class="white--text mr-1">Continue</v-btn>
                     <v-btn small color="fbcPrimary" class="white--text" @click="e1 = 3">Back</v-btn>
                   </div>
                 </v-stepper-content>
                 <v-stepper-content step="5" class="shadow-off pt-2 pb-0 pl-0 pr-0">

					
                   <div class="my-2" v-if="alaysisDetails.filecount === 'Two File'">
					<div v-if="Object.keys(rundetails).length > 1">
						<template v-for="(v, k) in verificationlist">
						<div :key="k" :class="`${selectedresulttab === k
												? 'border-left-primary'
												: 'border-left-transparent'
												}`" :id="'automation-test-'+ k">
							<div @click="selectedresulttab = k; generateAnnexurelist(v);" :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedresulttab === k ? 'grey lighten-4' : ''}`">
							<div class="mr-2">
								<v-tooltip bottom content-class="tooltip-bottom" v-if="(rundetails[v._id] || {}).stage === 1">
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
								</template>
								<span>Completed</span>
								</v-tooltip>
								<v-tooltip bottom content-class="tooltip-bottom" v-else-if="
													(rundetails[v._id] || {}).stage === 4
													">
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on" color="error">mdi-radiobox-marked</v-icon>
								</template>
								<span>Currently running</span>
								</v-tooltip>
								<v-tooltip bottom content-class="tooltip-bottom" v-else>
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
								</template>
								<span>Pending</span>
								</v-tooltip>
							</div>
							<div>
								{{ v.name }}
							</div>
							</div>
						</div>
						</template>
					</div>
                   <v-tabs v-model="resultTab" class="mb-2" v-if="annexurelist.length > 0">
                         <v-tab v-for="(item,index) in resultTabItem" :key="index" @click="collectionGet(item.value,index)" :id="'automation-test-'+index" :disabled="ailoadingimage">
                           <v-icon left v-if="index === 3">
							mdi-head-snowflake
							</v-icon>
						{{ item.name }}
                         </v-tab>
                       </v-tabs>
					<v-tabs-items v-model="resultTab">
                       <v-tab-item v-for="(item,index) in resultTabItem" :key="index" class="mb-2">
                        <div :id="'automation-test-'+ index">
							<v-row class="mt-3">
								<v-col cols="2" class="my-0 py-0" v-if="(resultTab !== 0 && resultTab !== 3)">
									<v-list dense class="my-0 py-0 mt-9" >
									<v-list-item-group  v-model="selectedCombotwo" class="my-0 py-0">
									<v-list-item
										v-for="(itemsf, i) in annexurelist.filter(itemsf => itemsf.name.includes(item.value))"
										:key="i"
										:value="i"
										>
										<v-list-item-content :id="'automation-test-' + i" @click="selecttwofilefun(itemsf.name,itemsf.value,selectedCombotwo)">
											<v-list-item-title v-text="itemsf.displayName" ></v-list-item-title>
										</v-list-item-content>
										</v-list-item>
										
									</v-list-item-group>
									</v-list>
								</v-col>
								<v-col :cols="(resultTab !== 0 && resultTab !== 3) ? 10:12" class="my-0 py-0">
									<div class="d-flex">
								<div v-if="filterArray.length > 0" class="ml-2 mb-2 grey--text caption">{{filterArray.length}} Filter Applyed <v-btn small color="fbcPrimary" class="ml-2 white--text" @click="reomveGobalefilter(selectcollection)">
									<v-icon class="mr-1">mdi-filter-remove-outline</v-icon>
									<span>Clear all filters</span>
									</v-btn>
								</div>
								<v-spacer></v-spacer>

								<v-btn color="fbcPrimary" class="mr-2 mb-2 shadow-off white--text" small  @click="MatchedAIitem(stagefilterAI)" v-if="(stagefilterAI.length > 0 && tabletype === 'AI')">
									Matched
								</v-btn>
								<v-btn color="fbcPrimary" class="mr-2 mb-2 shadow-off white--text" small @click="setupAI(resultTab,tabletype)" v-if="tabletype === 'AI'">
									<v-icon class="mr-1">mdi-memory</v-icon> Run AI
								</v-btn>
								<v-btn color="fbcPrimary" class="mr-2 mb-2 shadow-off white--text" small @click="startRunAIExecution(alaysisDetails.filecount)" v-if="(tabletype === 'AI' && executionbtnAI)">
									AI Execution Result
								</v-btn>
								<v-btn color="fbcPrimary" class="mr-2 mb-2 shadow-off white--text" small @click="downloadResult()" v-if="pageItems().length > 0 && !loading">

								<v-icon class="mr-1">mdi-file-excel</v-icon> Download Excel
							</v-btn>
								<div>
									<div class="radius-on border-on mb-2" v-if="pagecount > 1">
									<v-btn icon small :disabled="page === 1" @click="page--">
										<v-icon>mdi-chevron-left</v-icon>
									</v-btn>
									<span class="mx-1">Page {{ page }} of {{ pagecount }}</span>
									<v-btn icon small :disabled="page === pagecount" @click="page++">
										<v-icon>mdi-chevron-right</v-icon>
									</v-btn>
									</div>
								</div>
								</div>
								<div v-if="ailoadingimage"> 
									<div class="text-center fade-in-image">
									<img src="/img/loader_new.svg" />
								</div>
								</div>
								<div v-else>
								<div v-if="pageItems().length > 0">
								<v-simple-table dense class="FC-Table" fixed-header :height="pagecount > 1 ? '800px' : ''">
									<template v-slot:default>
									<thead>
										<tr v-if="tabletype === 'Summary'">
										<template v-for="(v, k) in pageItems()[0]">
										<th :key="k" v-if="k !== '_id'" :id="'automation-test-'+k" class="ellipsis-on">
											{{ (k || "").replaceAll("_", " ") }}
										</th>
										</template>
									</tr>
									<tr v-if="tabletype === 'Matched'">
									<th id="table" class="ellipsis-on">
                                   <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
                                     <template v-slot:activator="{ on, attrs }">
                                       <v-btn class="mx-0 px-0" small icon v-bind="attrs" v-on="on">
                                         <v-icon>mdi-filter-menu</v-icon>
                                       </v-btn>
                                     </template>
                                     <v-list dense class="border-left-default py-0">
                                       <v-list-item class="py-0 px-0 my-0">
                                         <v-list-item-title class="pa-2">
                                           <div class="d-flex align-center">
                                             <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="1"></v-checkbox>
                                             <v-icon class="success--text" left>mdi-check-circle</v-icon>
                                             <span class="success--text">Automated</span>
                                           </div>
                                         </v-list-item-title>
                                       </v-list-item>
                                       <v-list-item class="py-0 px-0 my-0">
                                         <v-list-item-title class="pa-2">
                                           <div class="d-flex align-center">
                                             <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="0"></v-checkbox>
                                             <v-icon class="info--text" left>mdi-timer-sand</v-icon>
                                             <span class="info--text">Suggested</span>
                                           </div>
                                         </v-list-item-title>
                                       </v-list-item>
                                     </v-list>
                                   </v-menu>
                                 </th>
										<template v-for="(v, k) in pageItems()[0]">
										<th class="ellipsis-on" :key="k" v-if="k !== '_id'" :id="'automation-test-'+k">
											{{ (k || "").replaceAll("_", " ") }}
										<v-btn class="grey--text text--lighten-1" small icon @click="globalfilterpop(k,selectedcollection)">
                                         <v-icon>mdi-filter</v-icon>
                                       </v-btn>
										</th>
										</template>
									</tr>
									<tr v-if="tabletype === 'Unmatched'">
										<th></th>
										<template v-for="(v, k) in pageItems()[0]">
										<th class="ellipsis-on" :key="k" v-if="k !== '_id'" :id="'automation-test-'+k">
											{{ (k || "").replaceAll("_", " ") }}
										<v-btn class="grey--text text--lighten-1" small icon @click="globalfilterpop(k,selectedcollection)">
                                         <v-icon>mdi-filter</v-icon>
                                       </v-btn>
										</th>
										</template>
									</tr>
									<tr v-if="tabletype === 'AI'">
										<th></th>
										<template v-for="(v, k) in pageItems()[0]">
										<th class="ellipsis-on" :key="k" v-if="k !== '_id'" :id="'automation-test-'+k">
											{{ (k || "").replaceAll("_", " ") }}
										<v-btn class="grey--text text--lighten-1" small icon @click="globalfilterpop(k,selectedcollection)">
                                         <v-icon>mdi-filter</v-icon>
                                       </v-btn>
										</th>
										</template>
									</tr>
									</thead>
									<tbody>
										<template v-for="(v, k) in  pageItems()" >
										<tr :key="k" :class="((tabletype === 'AI') && (v['Source'] === 'df1')) ? 'light-blue lighten-5': ''">
										<td class="" v-if="tabletype === 'Matched'" :id="'automation-test-'+k">
											<div class="d-flex align-center">
											<v-icon v-if="v['Match Nature'] === 'Automated'" color="success">mdi-check</v-icon>
											<v-icon v-else-if="v['Match Nature'] === 'Suggested'" color="info">mdi-timer-sand</v-icon>
											<template v-if="v['Match Nature'] === 'Suggested' && v['Verified'] !== 1">
												<v-btn icon small @click="moveTransaction(v, 'approve')" color="success">
												<v-icon>mdi-check-circle</v-icon>
												</v-btn>
												<v-btn icon small @click="moveTransaction(v, 'reject')" color="error">
												<v-icon>mdi-close-circle</v-icon>
												</v-btn>
											</template>
											</div>
										</td>
										<td  v-if="tabletype === 'Unmatched'" :id="'automation-test-'+k">
											<div class="d-flex align-center">
											<v-btn icon small @click="openMatchDialog(v,tabletype)" color="fbcPrimary" class="white--text">
												<v-icon>mdi-file-compare</v-icon>
											</v-btn>
											</div>
										</td>
										<td  v-if="tabletype === 'AI'" :id="'automation-test-'+k">
											<div class="d-flex align-center" v-if="v['Source'] !== null">
											<v-checkbox :id="'automation-test-'+ k" multiple :value="v" hide-details dense v-model="stagefilterAI" class="ma-0 pa-0"></v-checkbox>
											</div>
										</td>
										<template v-for="(vv, kk) in  pageItems()[0]">
											<td :key="kk" v-if="(kk !== '_id')" :id="'automation-test-'+kk"  style="{ background-color: red }">
												<div v-if="typeof v[kk] === 'number'">
													{{ $nova.formatNumber(v[kk].toFixed(0)) }}
												</div>
												<div v-else>
													{{isValidDate(v[kk])}}
												</div>
											</td>
										</template>
										</tr>
										</template>
									</tbody>
									</template>
								</v-simple-table>
								</div>
								<div v-else class="text-center grey--text title pa-6"> No data available </div>
								</div>
								</v-col>
							
							</v-row>
						</div>
					</v-tab-item>
					</v-tabs-items>
                   </div>
                   <div v-else>
                     <div class="d-flex">
                       <v-tabs v-model="resultTab" class="mb-2" v-if="comboAnnexer.length > 0">
                         <v-tab v-for="(item,index) in resultTabItem" :key="index" @click="comboCollectionGet(item.value,index)" :id="'automation-test-'+index" :disabled="ailoadingimage">
                           {{ item.name }}
                         </v-tab>
                       </v-tabs>
                     </div>
                     <v-tabs-items v-model="resultTab">
                       <v-tab-item v-for="(item,index) in resultTabItem" :key="index" >
                         <div>
                           <v-row class="mt-3" >
                             <v-col cols="2" class="my-0 py-0" v-if="(resultTab !== 0 && resultTab !== 3) && annexercomboKeys.length > 0">
                               <lb-dropdown hidedetails label="Filter" class="flex-grow-1 my-0 py-0 mt-9 " :items="annexercomboKeys" v-model="filterCombo"  v-if="annexercomboKeys.length > 0"/>
                               <v-list dense class="my-0 py-0">
                                 <v-list-item-group v-model="selectedCombo" class="my-0 py-0">
                                   <v-list-item v-for="(item, i) in annexercomboKeys" :key="i" :value="i" :id="'automation-test-'+ i">
                                     <v-list-item-content :id="'automation-test-'+i">
                                       <v-list-item-title v-text="item"></v-list-item-title>
                                     </v-list-item-content>
                                   </v-list-item>
                                 </v-list-item-group>
                               </v-list>
                             </v-col>
                             <v-col :cols="(resultTab !== 0 && resultTab !== 3) ? 10:12" class="my-0 py-0">
                               <div class="d-flex">
								<div v-if="filterArray.length >0" class="ml-2 mb-2 grey--text caption">{{filterArray.length}} Filter Applyed 
							<v-btn small color="fbcPrimary" class="ml-2 white--text" @click="reomveGobalefilter(selectcollection,selectkey)">
								<v-icon class="mr-1">mdi-filter-remove-outline</v-icon>
								<span>Clear all filters</span>
							</v-btn>
							</div>
                                 <v-spacer></v-spacer>


								<v-btn color="fbcPrimary" class="mr-2 mb-2 shadow-off white--text" small @click="setupAI(resultTab,tabletype)" v-if="tabletype === 'AI'">
									<v-icon class="mr-1">mdi-memory</v-icon> Run AI
								</v-btn>
								<v-btn color="fbcPrimary" class="mr-2 mb-2 shadow-off white--text" small @click="startRunAIExecution(alaysisDetails.filecount)" v-if="(tabletype === 'AI' && executionbtnAI)">
									AI Execution Result
								</v-btn>
								<v-btn color="fbcPrimary" class="mr-2 mb-2 shadow-off white--text" small @click="downloadResult()" v-if="pageItemsNew().length > 0 && !loading">

									<v-icon class="mr-1">mdi-file-excel</v-icon> Download Excel
								</v-btn>
                                 <div class="radius-on border-on mb-2" v-if="pagecountnew > 1 && !loading">
                                   <v-btn icon small :disabled="pagenew === 1" @click="pagenew--">
                                     <v-icon>mdi-chevron-left</v-icon>
                                   </v-btn>
                                   <span class="mx-1">Page {{ pagenew }} of {{ pagecountnew }}</span>
                                   <v-btn icon small :disabled="pagenew === pagecountnew" @click="pagenew++">
                                     <v-icon>mdi-chevron-right</v-icon>
                                   </v-btn>
                                 </div>
                               </div>

							<div v-if="ailoadingimage"> 
								<div class="text-center fade-in-image">
								<img src="/img/loader_new.svg" />
								</div>
							</div>
								<div v-else>
							<v-simple-table class="FC-Table" v-if="pageItemsNew().length > 0 && !loading" fixed-header  :height="pagecountnew > 1 ? '800px' : ''">

								<template v-slot:default>
									<thead>
									<tr v-if="tabletype === 'Summary'">
										<template v-for="(v, k) in pageItemsNew()[0]">
										<th :key="k" v-if="k !== '_id'" :id="'automation-test-'+k" class="ellipsis-on" >
											{{ (k || "").replaceAll("_", " ") }}
										</th>
										</template>
									</tr>
									<tr v-if="tabletype === 'Matched'">
									<th id="table" class="ellipsis-on">
                                   <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
                                     <template v-slot:activator="{ on, attrs }">
                                       <v-btn class="mx-0 px-0" small icon v-bind="attrs" v-on="on">
                                         <v-icon>mdi-filter-menu</v-icon>
                                       </v-btn>
                                     </template>
                                     <v-list dense class="border-left-default py-0">
                                       <v-list-item class="py-0 px-0 my-0">
                                         <v-list-item-title class="pa-2">
                                           <div class="d-flex align-center">
                                             <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilterNew" multiple :value="1"></v-checkbox>
                                             <v-icon class="success--text" left>mdi-check-circle</v-icon>
                                             <span class="success--text">Automated</span>
                                           </div>
                                         </v-list-item-title>
                                       </v-list-item>
                                       <v-list-item class="py-0 px-0 my-0">
                                         <v-list-item-title class="pa-2">
                                           <div class="d-flex align-center">
                                             <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilterNew" multiple :value="0"></v-checkbox>
                                             <v-icon class="info--text" left>mdi-timer-sand</v-icon>
                                             <span class="info--text">Suggested</span>
                                           </div>
                                         </v-list-item-title>
                                       </v-list-item>
                                     </v-list>
                                   </v-menu>
                                 </th>
										<template v-for="(v, k) in pageItemsNew()[0]">
										<th class="ellipsis-on" :key="k" v-if="k !== '_id'" :id="'automation-test-'+k">
											{{ (k || "").replaceAll("_", " ") }}
										<v-btn class="grey--text text--lighten-1" small icon @click="globalfilterpopNew(k)">
                                         <v-icon>mdi-filter</v-icon>
                                       </v-btn>
										</th>
										</template>
									</tr>
									<tr v-if="tabletype === 'Unmatched'">
										<th></th>
										<template v-for="(v, k) in pageItemsNew()[0]">
										<th class="ellipsis-on" :key="k" v-if="k !== '_id'" :id="'automation-test-'+k">
											{{ (k || "").replaceAll("_", " ") }}
										<v-btn class="grey--text text--lighten-1" small icon @click="globalfilterpopNew(k)">
                                         <v-icon>mdi-filter</v-icon>
                                       </v-btn>
										</th>
										</template>
									</tr>
									<tr v-if="tabletype === 'AI'">
									</tr>
									</thead>
									<tbody v-if="tabletype === 'Summary'">
									<tr v-for="(v, k) in pageItemsNew()" :key="k" :id="'automation-test-'+k">
										<template v-for="(vv, kk) in pageItemsNew()[0]">
										<td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+k">
											<div v-if="typeof v[kk] === 'number'">
													{{ $nova.formatNumber(v[kk].toFixed(0)) }}
												</div>
												<div v-else>
													{{isValidDate(v[kk])}}
												</div>
											<!-- {{ typeof v[kk] === "number" ? $nova.formatNumber(v[kk].toFixed(0)) : v[kk] }} -->
										</td>
										</template>
									</tr>
									</tbody>
									<tbody v-if="tabletype === 'Matched'">
									<tr v-for="(v, k) in pageItemsNew()" :key="k" :id="'automation-test-'+k">
									<td :id="'automation-test-'+k">
										<div class="d-flex align-center">
											<v-icon v-if="v['Match Nature'] === 'Automated'" color="success">mdi-check</v-icon>
											<v-icon v-else-if="v['Match Nature'] === 'Suggested'" color="info">mdi-timer-sand</v-icon>
											<template v-if="v['Match Nature'] === 'Suggested' && v['Verified'] !== 1">
											<v-btn icon small @click="moveTransactionNew(v, 'approve')" color="success">
												<v-icon>mdi-check-circle</v-icon>
											</v-btn>
											<v-btn icon small @click="moveTransactionNew(v, 'reject')" color="error">
												<v-icon>mdi-close-circle</v-icon>
											</v-btn>
											</template>
										</div>
										</td>
										<template v-for="(vv, kk) in pageItemsNew()[0]">
										<td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+kk">
											{{ typeof v[kk] === "number" ? $nova.formatNumber(v[kk].toFixed(0)) : v[kk] }}
										</td>
										</template>
									</tr>
									</tbody>
									<tbody v-if="tabletype === 'Unmatched'">
									<tr v-for="(v, k) in pageItemsNew()" :key="k" :id="'automation-test-'+k">
										<td :id="'automation-test-'+ k">
										<div class="d-flex align-center">
											<v-btn icon small @click="openMatchDialogNew(v)" color="primary">
											<v-icon>mdi-file-compare</v-icon>
											</v-btn>
										</div>
										</td>
										<template v-for="(vv, kk) in pageItemsNew()[0]">
										<td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+ kk">
											{{ typeof v[kk] === "number" ? $nova.formatNumber(v[kk].toFixed(0)) : v[kk] }}
										</td>
										</template>
									</tr>
									</tbody>
									<tbody v-if="tabletype === 'AI'">
									<tr v-for="(v, k) in pageItemsNew()" :key="k" :id="'automation-test-'+k">
										<td :id="'automation-test-'+ k">
										<div class="d-flex align-center">
											<v-btn icon small @click="openMatchDialogNew(v)" color="fbcPrimary" class="white--text">
											<v-icon>mdi-file-compare</v-icon>
											</v-btn>
										</div>
										</td>
										<template v-for="(vv, kk) in pageItemsNew()[0]">
										<td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+ kk">
											{{ typeof v[kk] === "number" ? $nova.formatNumber(v[kk].toFixed(0)) : v[kk] }}
										</td>
										</template>
									</tr>
									</tbody>
								</template>
							</v-simple-table>
								<div v-else class="text-center grey--text title pa-6"> No data available </div>
								</div>
                             </v-col>
                           </v-row>
                         </div>
                       </v-tab-item>
                     </v-tabs-items>
                   </div>
                 </v-stepper-content>
				<v-stepper-content step="6" class="shadow-off pt-2 pb-0 pl-0 pr-0" v-if="fifoalaysis">
                       <v-row>
                         <v-col>
                          <div class="mt-4">
							<div class="d-flex mb-3">
							<v-spacer></v-spacer>
							<v-btn color="fbcPrimary" class="ml-2 shadow-off white--text" small @click="downloadResult()">
								Export 
							</v-btn>
							<v-btn color="fbcPrimary" class="ml-2 shadow-off white--text" small @click="startRunFifo()">
								Run FIFO
							</v-btn>
							<v-btn color="fbcPrimary" class="ml-2 shadow-off white--text" small @click="startRunFifoExecution()" >
								FIFO Execution Result
							</v-btn>
							<div class="radius-on border-on mb-2 ml-2" v-if="pagecountfifo.length > 1 && !loading">
                                   <v-btn icon small :disabled="pagefifo === 1" @click="pagefifo--">
                                     <v-icon>mdi-chevron-left</v-icon>
                                   </v-btn>
                                   <span class="mx-1">Page {{ pagefifo }} of {{ pagecountfifo }}</span>
                                   <v-btn icon small :disabled="pagefifo === pagecountfifo" @click="pagefifo++">
                                     <v-icon>mdi-chevron-right</v-icon>
                                   </v-btn>
                                 </div>
							</div>

							<div class="" v-if="pageItemsfifo().length > 0">
								<v-simple-table dense class="FC-Table" :fixed-header="true">
								<template v-slot:default>
									<thead>
									<tr class="">
										<th class="name-column" id="select">Select All 
											<span class="ml-1">
											<input type="checkbox" v-model="selectallfifo" @change="toggleSelectAll" />
											</span>
										</th>
										<template v-for="(v, k) in pageItemsfifo()[0]">
										<th class="" :key="k" v-if="k !== '_id'" :id="'automation-test-'+k">
											{{ (k || "").replaceAll("_", " ") }}
										</th>
										</template>
									</tr>
									</thead>
									<tbody>
									<tr v-for="(v, k) in pageItemsfifo()" :key="k" :id="'automation-test-'+k">
										<td>
										<input type="checkbox" v-model="selectFifo" :value="k" multiple :id="'automation-test-'+k">
										</td>
										<template v-for="(vv, kk) in pageItemsfifo()[0]">
										<td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+k">
											{{
											typeof v[kk] === "number"
											? $nova.formatNumber(v[kk].toFixed(0))
											: v[kk]
											}}
										</td>
										</template>
									</tr>
									</tbody>
								</template>
								</v-simple-table>
							</div>
							<div v-else class="text-center grey--text title pa-6">
								No data available
							</div>
						</div>
                         </v-col>
                       </v-row>
                 </v-stepper-content>
                 <v-stepper-content :step="fifoalaysis ? 7 : 6" class="shadow-off pt-2 pb-0 pl-0 pr-0">
                       <v-row>
                         <v-col>
                           <div>
                             <v-btn small color="fbcPrimary" class="mr-1 mb-2 white--text" @click="openRecoincilationDialogue()">
                               <v-icon class="mr-1">mdi-plus</v-icon>New
                             </v-btn>
                              <v-simple-table class="FC-Table" dense v-if="recoincilationSummaryData.length > 0">
								<template v-slot:default>
									<thead>
									<tr>
										<th id="description">Description</th>
										<th id="subamount">Sub Amount</th>
										<th id="amount">Final Amount</th>
									</tr>
									</thead>
									<tbody>
									<tr v-for="(item, k) in recoincilationSummaryData" :key="item._id" :id="'automation-test-'+k" >
										<td :id="'automation-test-'+k">
										<div v-if="item && item.Reconcilliation && item.Reconcilliation.includes('Total Excluded')">
											<div v-for="(values, key) in parsedAmount(item.Amount)" :key="key">
											<div v-if="key === 'ADD' && values.length" :id="'automation-test-'+key">
												<span v-for="(subItem, index) in values" :key="index" :id="'automation-test-'+index">
												<div v-for="(value, key) in subItem" :key="key" :id="'automation-test-'+key">
													Add : {{ key }} 
												</div>
												</span>
											</div>
											<div v-if="key === 'LESS' && values.length">
												<span v-for="(subItem, index) in values" :key="index" :id="'automation-test-'+index">
												<div v-for="(value, key) in subItem" :key="key" :id="'automation-test-'+key">
													Less : {{ key }}
												</div>
												</span>
											</div>
											</div>
										</div>
										<div v-else>
											{{ item.Reconcilliation }}
										</div>
										</td>
										<td :id="'automation-test-'+k">
										<div v-if="item && item.Reconcilliation && item.Reconcilliation.includes('Total Excluded')">
											<div v-for="(values, key) in parsedAmount(item.Amount)" :key="key" :id="'automation-test-'+key">
											<div v-if="key === 'ADD' && values.length" :id="'automation-test-'+ key">
												<span v-for="(subItem, index) in values" :key="index" :id="'automation-test-'+index">
												<div v-for="(value, key) in subItem" :key="key" :id="'automation-test-'+key" style="margin-left: 8px">
													{{ $nova.formatNumber(value) }}
												</div>
												</span>
											</div>
											<div v-if="key === 'LESS' && values.length">
												<span v-for="(subItem, index) in values" :key="index" :id="'automation-test-'+index">
												<div v-for="(value, key) in subItem" :key="key" :id="'automation-test-'+key" style="margin-left: 8px">
													{{ $nova.formatNumber(value) }}
												</div>
												</span>
											</div>
											</div>
										</div>
										<div v-else-if="item && item.Reconcilliation && (item.Reconcilliation.includes('Unmatched Total in') || item.Reconcilliation.includes('Unmatched Total in Table 1'))">
											{{ $nova.formatNumber(item['Sub Amount'].toFixed(0)) }}
										</div>
										<div v-else>
											{{ item && item["Sub Amount"] }} 
										</div>
										</td>
										<td :id="'automation-test-'+k">
										<div v-if="item && item.Reconcilliation && (item.Reconcilliation.includes('Total Amount as per') || item.Reconcilliation.includes('Total Amount as per Table 2'))">
											{{ $nova.formatNumber(item["Amount"].toFixed(0)) }}
										</div>
										<div v-else>
											<div v-if="item.Amount && item.Amount.ADD">
											{{ item.Amount.ADD }} 
											</div>
										</div>
										</td>
									</tr>
									</tbody>
								</template>
							</v-simple-table>
							<div v-else class="text-center grey--text title pa-6">No data available</div>
                           </div>
                         </v-col>
                       </v-row>
                 </v-stepper-content>
               </v-stepper-items>
             </v-stepper>
           </div>
         </v-card-text>
       </v-card>
     </div>
   </div>
   <lb-dailogboxnew v-model="addeditdialog" :heading="`${(addeditdata.__type === 'add') ? 'Add Rule' : 'Edit Rule'}`" width="600" :loading="addeditloading">
     <template v-slot:body>
       <div>
         <v-row>
           <v-col cols="6">
             <div class="d-flex align-center">
               <lb-dropdown class="flux-grow-1 mr-1" label="Match Flag" hidedetails :items="rulenaturelist" v-model="addeditdata.nature" />
               <lb-helpmenu>
                 <template #text>
                   <div class="pa-4">
                     <span class="font-weight-bold mr-1">Automated Matching:</span>
                     <span class="">In the case of automated matching, a predefined rule is applied to automatically consider transactions (line-items) as matched. Once the rule conditions are met, the transactions are automatically paired without requiring manual intervention or approval.</span>
                     <br />
                     <br />
                     <span class="font-weight-bold mr-1">Suggested Matching:</span>
                     <span class="">In the case of suggested matching, a set of rules is used to suggest potential matches between transactions (line-items). However, these suggested matches go through a process of approval before being considered final. The suggested matches are presented to users for review and validation, allowing them to confirm or modify the proposed matches based on their expertise and judgment.</span>
                     <br />
                   </div>
                 </template>
               </lb-helpmenu>
             </div>
           </v-col>
           <v-col cols="6">
             <div class="d-flex align-center">
               <lb-dropdown class="flux-grow-1 mr-1" label="Relation" hidedetails :items="ruletypelist" v-model="addeditdata.type" />
               <lb-helpmenu>
                 <template #text>
                   <div class="pa-4">
                     <span class="font-weight-bold mr-1">1:1 Matching:</span>
                     <span class="">This scenario involves matching one transaction (line-item) with another transaction. Each transaction is paired with another transaction, resulting in a one-to-one relationship.</span>
                     <br />
                     <br />
                     <span class="font-weight-bold mr-1">1:M Matching:</span>
                     <span class="">In this scenario, one transaction (line-item) is matched with a group of transactions. The transaction is associated with multiple related transactions, resulting in a one-to-many relationship.</span>
                     <br />
                     <br />
                     <span class="font-weight-bold mr-1">M:1 Matching:</span>
                     <span class="">In the M:1 scenario, a group of transactions (line-items) is matched with one transaction. Multiple transactions are associated with single transactions, resulting in a many-to-one relationship.</span>
                     <br />
                     <br />
                     <span class="font-weight-bold mr-1">M:M Matching:</span>
                     <span class="">This scenario involves matching a group of transactions (line-items) with another group of transactions. Each transaction within one group is paired with multiple transactions within another group, resulting in a many-to-many relationship.</span>
                   </div>
                 </template>
               </lb-helpmenu>
             </div>
           </v-col>
           <v-col cols="12">
             <div class="font-weight-bold mb-1">String matching rule</div>
             <template v-for="(v, k) in columns">
               <div v-if="v.type === 'string'" :key="k" class="d-flex align-top" :id="'automation-test-'+ k">
                 <v-checkbox dense hide-details class="ma-0 pa-0 mr-2" :value="k" v-model="selectedrulecolumn" multiple></v-checkbox>
                 <div>
                   <div>
                     <v-chip :id="'automation-test-'+ k" x-small label :style="`background-color:${colors[1]} !important`" class="px-2">{{ v.data1 }}</v-chip>
                     <span class="mx-2">with</span>
                     <v-chip :id="'automation-test-'+ k" x-small label :style="`background-color:${colors[2]} !important`" class="px-2">{{ v.data2 }}</v-chip>
                   </div>
                   <div v-if="selectedrulecolumn.indexOf(k) > -1" class="mb-2 d-flex mt-2">
                     <lb-dropdown :id="'automation-test-'+ k" class="mr-1" label="Type" hidedetails :items="strmatchtype" @change="addeditdatacols[k].in = null; addeditdatacols[k].matchratio = null; selectedrulecolumn.push('xxx'); selectedrulecolumn.pop()" v-model="addeditdatacols[k].type" />
                     <lb-dropdown :id="'automation-test-'+ k" v-if="addeditdatacols[k].type && addeditdatacols[k].type !== 'exact'" class="mr-1" label="In Column" @change="addeditdatacols[k].matchratio = null; selectedrulecolumn.push('xxx'); selectedrulecolumn.pop()" hidedetails :items="[{ name: '1 - ' + columns[k]?.data1, value: 'first' }, { name: '2 - ' + columns[k]?.data2, value: 'second' }]" v-model="addeditdatacols[k].in" />
                     <lb-number :id="'automation-test-'+ k" min="0" v-if="addeditdatacols[k].type === 'approximate match'" prependiconinner="mdi-percent" class="mr-1" label="Match %" @change="selectedrulecolumn.push('xxx'); selectedrulecolumn.pop()" hidedetails v-model="addeditdatacols[k].matchratio" />
                   </div>
                 </div>
               </div>
             </template>
           </v-col>
           <v-col cols="12">
             <div class="font-weight-bold mb-1">Number & Date matching rule</div>
             <template v-for="(v, k) in columns">
               <div v-if="v.type === 'number' || v.type === 'date'" :key="k" class="d-flex align-top" :id="'automation-test-'+ k">
                 <v-checkbox dense hide-details class="ma-0 pa-0 mr-2" :value="k" v-model="selectedrulecolumn" multiple></v-checkbox>
                 <div>
                   <div>
                     <v-chip :id="'automation-test-'+ k" x-small label :style="`background-color:${colors[1]} !important`" class="px-2">{{ v.data1 }}</v-chip>
                     <span class="mx-2">with</span>
                     <v-chip  :id="'automation-test-'+ k" x-small label :style="`background-color:${colors[2]} !important`" class="px-2">{{ v.data2 }}</v-chip>
                   </div>
                   <div v-if="selectedrulecolumn.indexOf(k) > -1" class="mb-2 d-flex mt-2">
                     <lb-number :id="'automation-test-'+ k" :min="(v.type === 'number' && addeditdatacols[k].negative)  ? '' : '0'" class="mr-1" :label="`${(v.type === 'number') ? 'Number ' : 'Days'} Range`" @change="selectedrulecolumn.push('xxx'); selectedrulecolumn.pop()" hidedetails v-model="addeditdatacols[k].type" />
                   </div>
				<div v-if="selectedrulecolumn.indexOf(k) > -1">
				<v-checkbox :id="'automation-test-'+ k" v-if="v.type === 'number'" dense label="Opposite Sign Match" v-model="addeditdatacols[k].negative" @change="selectedrulecolumn.push('xxx'); selectedrulecolumn.pop()"></v-checkbox>
				</div>
                </div>
               </div>
             </template>
           </v-col>
           <v-col cols="12" v-if="addeditdata.type === 'M:1' || addeditdata.type === '1:M' || addeditdata.type === 'M:M'">
             <div class="font-weight-bold mb-1">Grouping rule</div>
             <div class="ml-2">
               <div>
                 <v-icon>mdi-circle-small</v-icon>Criteria considered for grouping
               </div>
               <div class="ml-4 mb-2">
                 <div v-if="addeditdata.type === 'M:1' || addeditdata.type === 'M:M'">
                   <template v-for="(v, k) in selectedrulecolumn">
                     <v-chip :id="'automation-test-'+ k" :key="k" v-if="columns[v].type === 'string' || columns[v].type === 'date'" x-small label :style="`background-color:${colors[1]} !important`" class="px-2 mr-1">{{ columns[v].data1 }}</v-chip>
                   </template>
                   <span class="ml-1">in {{ filenames[0] }}</span>
                 </div>
                 <div v-if="addeditdata.type === '1:M' || addeditdata.type === 'M:M'">
                   <template v-for="(v, k) in selectedrulecolumn">
                     <v-chip :id="'automation-test-'+ k" :key="k" v-if="columns[v].type === 'string' || columns[v].type === 'date'" x-small label :style="`background-color:${colors[2]} !important`" class="px-2 mr-1">{{ columns[v].data1 }}</v-chip>
                   </template>
                   <span class="ml-1">in {{ filenames[1] }}</span>
                 </div>
               </div>
               <div>
                 <v-icon>mdi-circle-small</v-icon>Aggregation Methods
               </div>
               <div class="ml-4">
                 <template v-for="(v, k) in columns">
                   <div :id="'automation-test-'+ k" v-if="v.type === 'number' && selectedrulecolumn.indexOf(k) > -1" :key="k">
                     <div class="d-flex align-center mb-1">
                       <v-chip :id="'automation-test-'+ k" v-if="addeditdata.type === 'M:1' || addeditdata.type === 'M:M'" x-small label :style="`background-color:${colors[1]} !important`" class="px-2">{{ v.data1 }}</v-chip>
                       <span class="mx-2" v-if="addeditdata.type === 'M:M'">contains in</span>
                       <v-chip :id="'automation-test-'+ k" v-if="addeditdata.type === '1:M' || addeditdata.type === 'M:M'" x-small label :style="`background-color:${colors[2]} !important`" class="px-2">{{ v.data2 }}</v-chip>
                       <lb-dropdown :id="'automation-test-'+ k" class="ml-1" label="Method" hidedetails :items="aggregationtype" @click="selectedrulecolumn.push('xxx'); selectedrulecolumn.pop()" v-model="aggregationtypesection[k]" />
                       <v-spacer class="flex-grow-1"></v-spacer>
                     </div>
                   </div>
                 </template>
               </div>
             </div>
           </v-col>
         </v-row>
       </div>
     </template>
     <template v-slot:actions>
       <span class="caption">*Required</span>
       <v-spacer></v-spacer>
       <v-btn color="fbcPrimary" v-if="addeditdata.__type === 'add'" small @click="updateRule('add')" class="white--text">Add</v-btn>
       <v-btn color="fbcPrimary" v-else small @click="updateRule('edit')" class="white--text">Edit</v-btn>
     </template>
   </lb-dailogboxnew>

   <lb-dailogboxnew v-model="comparedialog" heading="Select Matches" width="12000" :loading="compareloading">
     <template v-slot:body>
       <div>
		<div>
		<div class="body-1 my-2">Selected Item :</div>
		<v-simple-table dense class="FC-Table mb-4" v-if="twofileselectiteamComparing.length > 0">
           <template v-slot:default>
             <thead>
               <tr class="">
                 <template v-for="(v, k) in twofileselectiteamComparing[0]">
                   <th :key="k" v-if="k !== '_id'" :id="'automation-test-'+ k">
                     {{ (k || "").replaceAll("_", " ") }}
                   </th>
                 </template>
               </tr>
             </thead>
             <tbody>
               <tr v-for="(v, k) in twofileselectiteamComparing" :key="k" :id="'automation-test-'+k">
                 <template v-for="(vv, kk) in twofileselectiteamComparing[0]">
                   <td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+ kk">
                     {{ typeof v[kk] === "number" ? $nova.formatNumber(v[kk].toFixed(0)) : v[kk] }}
                   </td>
                 </template>
               </tr>
             </tbody>
           </template>
         </v-simple-table>
		</div>
         <lb-string v-model="searchvalue" hidedetails label="Search" @change="filterMatchItemsNewfilterTwo()" />
         <div class="body-1 my-2">{{ (selectedids || []).length }} Selected</div>
         <v-simple-table dense class="FC-Table" :fixed-header="true">
           <template v-slot:default>
             <thead>
               <tr class="">
                 <th></th>
                 <template v-for="(v, k) in formateddataforfilter[0]">
                   <th class="" :key="k" v-if="k !== '_id'" :id="'automation-test-'+ k">
                     {{ (k || "").replaceAll("_", " ") }}
					<v-btn class="grey--text text--lighten-1" small icon @click="globalfilterpopMatch(k,matchCollection,formateddataforfilter,'Manual','twofile')">
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>
                   </th>
                 </template>
               </tr>
             </thead>
             <tbody>
               <tr v-for="(v, k) in formateddataforfilter" :key="k">
                 <td :id="'automation-test-'+ k">
                   <v-checkbox :id="'automation-test-'+ k" multiple :value="v[filterselectkey]" hide-details dense v-model="selectedids" class="ma-0 pa-0"></v-checkbox>
                 </td>
                 <template v-for="(vv, kk) in formateddataforfilter[0]">
                   <td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+ kk">
                     {{ typeof v[kk] === "number" ? $nova.formatNumber(v[kk].toFixed(0)) : v[kk] }}
                   </td>
                 </template>
               </tr>
             </tbody>
           </template>
         </v-simple-table>
       </div>
     </template>
     <template v-slot:actions>
       <span class="caption">*Required</span>
       <v-spacer></v-spacer>
       <v-btn color="fbcPrimary" small @click="moveTransaction(comparingitem, 'match')" class="white--text">Match Selected</v-btn>
     </template>
   </lb-dailogboxnew>

   <lb-dailogboxnew v-model="comparedialogNew" heading="Select Matches" width="1200" :loading="compareloadingNew">
     <template v-slot:body>
       <div>
		<div>
		<div class="body-1 my-2">Selected Item :</div>
		<v-simple-table dense class="mb-4 FC-Table" v-if="selectiteamComparing.length > 0">
           <template v-slot:default>
             <thead>
               <tr class="">
                 <template v-for="(v, k) in selectiteamComparing[0]">
                   <th :key="k" v-if="k !== '_id'" :id="'automation-test-'+ k">
                     {{ (k || "").replaceAll("_", " ") }}
                   </th>
                 </template>
               </tr>
             </thead>
             <tbody>
               <tr v-for="(v, k) in selectiteamComparing" :key="k" :id="'automation-test-'+k">
                 <template v-for="(vv, kk) in selectiteamComparing[0]">
                   <td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+ kk">
                     {{ typeof v[kk] === "number" ? $nova.formatNumber(v[kk].toFixed(0)) : v[kk] }}
                   </td>
                 </template>
               </tr>
             </tbody>
           </template>
         </v-simple-table>
		</div>
         <lb-string v-model="searchvalueNew" hidedetails label="Search" @change="filterMatchItemsNewfilter()" />
         <div class="body-1 my-2">{{ (selectedidsNew || []).length }} Selected</div>
         <v-simple-table dense class="FC-Table" :fixed-header="true" v-if="formateddataforfilterNew.length > 0">
           <template v-slot:default>
             <thead>
               <tr class="">
                 <th></th>
                 <template v-for="(v, k) in formateddataforfilterNew[0]" >
                   <th class="" :key="k" v-if="k !== '_id'" :id="'automation-test-'+ k">
                     {{ (k || "").replaceAll("_", " ") }}
					<v-btn class="grey--text text--lighten-1" small icon @click="globalfilterpopMatch(k,matchCollection,formateddataforfilterNew,'Manual','single')">
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>
                   </th>
                 </template>
               </tr>
             </thead>
             <tbody>
               <tr v-for="(v, k) in formateddataforfilterNew" :key="k" :id="'automation-test-'+k">
                 <td :id="'automation-test-'+ k">
                   <v-checkbox multiple :value="v[filterselectkeyNew]" hide-details dense v-model="selectedidsNew" class="ma-0 pa-0"></v-checkbox>
                 </td>
                 <template v-for="(vv, kk) in formateddataforfilterNew[0]">
                   <td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+ kk">
                     {{ typeof v[kk] === "number" ? $nova.formatNumber(v[kk].toFixed(0)) : v[kk] }}
                   </td>
                 </template>
               </tr>
             </tbody>
           </template>
         </v-simple-table>
       </div>
     </template>
     <template v-slot:actions>
       <span class="caption">*Required</span>
       <v-spacer></v-spacer>
       <v-btn color="fbcPrimary" small @click="moveTransactionNew(comparingitemNew, 'match')" class="white--text">Match Selected</v-btn>
     </template>
   </lb-dailogboxnew>

   <lb-dailogboxnew v-model="recoincilationDialogue" heading="Add New Reconciliation" width="600" :loading="false">
     <template v-slot:body>
       <div>
         <v-row>
           <v-col cols="12" class="my-0 py-0">
             <lb-string v-model="recoincilationItem.description" label="Description*" :error="recoincilationErrorItem.description" />
           </v-col>
           <v-col cols="12" class="my-0 py-0">
             <lb-number v-model="recoincilationItem.subamount" label="Sub Amount*" :error="recoincilationErrorItem.subamount" />
           </v-col>
           <!-- <v-col cols="12" class="my-0 py-0">
             <lb-number v-model="recoincilationItem.finalamount" label="Final Amount*" :error="recoincilationErrorItem.finalamount" />
           </v-col> -->
           <!-- <v-col cols="12" class="my-0 py-1"><lb-file v-model="recoincilationItem.attachmentfile" label="" class="py-0 my-0" :drag="false" hidedetails
                :displayname="true" accept=".xlsx,.xls,.xlsm,.xlsb" /></v-col> -->
         </v-row>
       </div>
     </template>
     <template v-slot:actions>
       <v-spacer></v-spacer>
       <v-btn small color="fbcPrimary" class="white--text" @click="addRecoincilationItem(recoincilationCollection)">
         <!-- <span v-if="locationdata._id" @click="updateLocation(locationdata)">Update</span> -->
         <span >Add</span>
       </v-btn>
     </template>
   </lb-dailogboxnew>
   <lb-dailogboxnew v-model="addfilterdialog" :heading="isEditing ? 'Update Filter' : 'Add Filter'" width="600" :loading="addfilterdialogloading">
     <template v-slot:body>
       <div>
         <v-row>
           <v-col cols="8" class="my-0 py-0">
             <lb-string v-model.trim="filterobject.description" label="Description*" :error="filterobjecterror.description" />
           </v-col>
           <v-col cols="4" class="my-0 py-0">
             <lb-string v-model.trim="filterobject.tag" label="Tag*" :error="filterobjecterror.tag" />
           </v-col>
           <v-col cols="6" class="my-0 py-0 mb-4">
             <span>Whether to exclude or delete the row:</span>
           </v-col>
           <v-col cols="6" class="my-0 py-0 mb-4">
             <v-radio-group v-model="filterobject.excludetype" row class="pt-0 mt-0" dense hide-details :error-messages="filterobjecterror.excludetype" :error="filterobjecterror.excludetype">
               <v-radio v-for="(v, i) in filterrowType" :key="i" :label="v" class="my-0" :value="v" :id="'automation-test-'+ i"></v-radio>
             </v-radio-group>
           </v-col>
           <v-col cols="6" class="my-0 py-0">
             <lb-dropdown class="flux-grow-1 mr-1" label="Select Table*" :items="setupList.file" itemtext="name" itemvalue="_id" v-model="filterobject.tableid" :error="filterobjecterror.tableid" :key="new Date().getTime()"/>
           </v-col>
           <v-col cols="6" class="my-0 py-0">
             <lb-dropdown class="flux-grow-1 mr-1" label="Select Column*" :items="filtercolums" itemtext="name" itemvalue="_id" v-model="filterobject.column" :error="filterobjecterror.column" />
           </v-col>
           <v-col cols="12" class="my-0 py-0">
             <lb-dropdown class="flux-grow-1 mr-1" label="Filter Criteria*" :items="operatorType" itemtext="name" itemvalue="_id" v-model="filterobject.operator" :error="filterobjecterror.operator" />
           </v-col>
           <v-col cols="12" class="my-0 py-0">
             <lb-string v-model.trim="filterobject.filterword" label="Filter text or number*" :error="filterobjecterror.filterword" />
           </v-col>
         </v-row>
       </div>
     </template>
     <template v-slot:actions>
       <v-spacer></v-spacer>
       <v-btn small color="fbcPrimary" class="white--text" v-if="isEditing" @click="updatefilterPush(filterobject._id)">
         <span>Update</span>
       </v-btn>
       <v-btn small color="fbcPrimary" class="white--text" @click="addfilterPush()" v-else>
         <span>Add</span>
       </v-btn>
     </template>
   </lb-dailogboxnew>
   <lb-dailogboxnew v-model="addfilterconfim" heading="Confirmation" width="400" :loading="addfilterconfimloading">
     <template v-slot:body>
       <div>
         Please save your changes made on the Filter screen
       </div>
     </template>
     <template v-slot:actions>
       <v-spacer></v-spacer>
       <v-btn small color="fbcPrimary" class="white--text" @click="addfilter('continue')">
         <span>Save</span>
       </v-btn>
       <v-btn small color="fbcPrimary" class="white--text" @click="getfilterlistCancel()">
         <span>Cancal</span>
       </v-btn>
     </template>
   </lb-dailogboxnew>
   <lb-dailogboxnew v-model="addrulesconfim" heading="Confirmation" width="400" :loading="addrulesconfimloading">
     <template v-slot:body>
       <div>
         Please save your changes made on the matching routines screen
       </div>
     </template>
     <template v-slot:actions>
       <v-spacer></v-spacer>
       <v-btn small color="fbcPrimary" class="white--text" @click="saveInputParameters()">
         <span>Save</span>
       </v-btn>
       <v-btn small color="fbcPrimary" class="white--text" @click="getruleslistCancel()">
         <span>Cancal</span>
       </v-btn>
     </template>
   </lb-dailogboxnew>
   <lb-dailogboxnew v-model="addMiddlewaredialog" :heading="middlewareObject._id ? 'Update Mapping Master' : 'Mapping Master'" width="600" :loading="addMiddlewaredialogloading">
     <template v-slot:body>
       <div>
         <v-row>
           <v-col cols="12" class="my-1 py-1">
             <lb-string v-model="middlewareObject.middlewarename" label="File Name*" :error="middlewareObjecterror.name" />
           </v-col>
           <v-col cols="12" class="my-0 py-0">
             <lb-dropdown class="flux-grow-1 mr-1" label="Link Source File to Master" :items="setupList.file" itemtext="name" itemvalue="_id" v-model="middlewareObject.basefile" :error="middlewareObjecterror.basefile" :key="new Date().getTime()"/>
           </v-col>
           <v-col cols="6" class="my-0 py-0">
             <lb-file v-model="middFile" label="" class="py-0 my-0" :drag="false" hidedetails :displayname="true" accept=".xlsx,.xls,.xlsm,.xlsb,.csv" />
           </v-col>
		<v-col cols="6" class="my-0 py-0">
			<span class="mt-2 ml-3 error--text">
			{{ middlewareObjecterror.middFile }}
			</span>
			</v-col>
         </v-row>
       </div>
     </template>
     <template v-slot:actions>
       <v-spacer></v-spacer>
       <v-btn small color="fbcPrimary" class="white--text" v-if="middlewareObject._id" @click="updateMiddleware(middlewareObject._id)">
         <span>Update</span>
       </v-btn>
       <v-btn small color="fbcPrimary" class="white--text" @click="addMiddleware()" v-else>
         <span>Add</span>
       </v-btn>
     </template>
   </lb-dailogboxnew>

	<lb-dailogboxnew v-model="runfifoppop" heading='FIFO Execution Results' width="1000" :loading="false">
	<template v-slot:body>
		<div>
			<div style="">
			<div class="" v-if="fifoexecutionResult.length > 0">
				<v-simple-table dense class="FC-Table" :fixed-header="true">
				<template v-slot:default>
					<thead>
					<tr>
						<template v-for="(v, k) in fifoexecutionResult[0]">
						<th :class="{ 'active': sortColumn === k }" :key="k" v-if="k !== '_id'" @click="sortBy(k)" :id="'automation-test-'+k">
							{{ (k || "").replaceAll("_", " ") }}
							<v-icon class="ml-2" large v-if="sortColumn === k" :id="'automation-test-'+k">{{ sortDesc ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon>
						</th>
						</template>
					</tr>
					</thead>
					<tbody>
					
					<tr v-for="(v, k) in sortedData" :key="k" :id="'automation-test-'+k">
						<template v-for="(vv, kk) in fifoexecutionResult[0]">
						<td :key="kk" v-if="kk !== '_id'" :id="'automation-test-'+kk">
							{{ typeof v[kk] === "number"
							? $nova.formatNumber(v[kk].toFixed(0))
							: v[kk]
							}}
						</td>
						</template>
					</tr>
					</tbody>
				</template>
				</v-simple-table>
			</div>
			<div v-else class="text-center grey--text title pa-6"> No data available </div>
			</div>
		</div>
		</template>
	</lb-dailogboxnew>

	<v-dialog v-model="selectfilterdialog" transition="dialog-right-transition" hide-overlay persistent :loading="selectfilterdialogloading">
       <v-card class="dialog-right" :loading="selectfilterdialogloading" :disabled="selectfilterdialogloading" loader-height="2">
        <v-card-title class="fbcPrimary align-center py-2 px-4">

          <span class="subtitle-2 white--text">Filter properties</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="selectfilterdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="mt-5" :disabled="compareObjects">
          <v-row>
		<div class="mb-5 ml-3">
		<span class=""> Select Filter : <span class="font-weight-black">{{selectFilter}} </span></span>
		<span class="mb-3"> Type : <span class="font-weight-black">{{typeOffilter}} </span></span>
		</div>
           <v-col cols="12" class="my-0 py-1" v-if="typeOffilter === 'Number'">
             <lb-dropdown label="Select Operation"  :items="filteritemNumber" v-model="globalfilterObject.operation" :error="globalfilterObjecterror.operation" :disabled="compareObjects"/>
            <lb-number label="Range 1" :items="filteritemNumber" v-model="globalfilterObject.range1" :error="globalfilterObjecterror.range1" :disabled="compareObjects"/>
			<lb-number label="Range 2" :items="filteritemNumber" v-model="globalfilterObject.range2" :error="globalfilterObjecterror.range2" :disabled="compareObjects" v-if="(globalfilterObject.operation === 'Between' && typeOffilter === 'Number')" />
		</v-col>
		<v-col cols="12" class="my-0 py-1" v-if="typeOffilter === 'Text'">
            <lb-dropdown label="Select Operation"  :items="filteritemString" v-model="globalfilterObject.operation" :error="globalfilterObjecterror.operation" :disabled="compareObjects"/>
			<lb-string label="Range"  :items="filteritemNumber" v-model="globalfilterObject.range" :error="globalfilterObjecterror.range" :disabled="compareObjects"/>
           </v-col>
		<v-col cols="12" class="my-0 py-1" v-if="typeOffilter === 'Date'">
             <lb-dropdown label="Select Operation"  :items="filteritemDate" :disabled="compareObjects" v-model="globalfilterObject.operation" :error="globalfilterObjecterror.operation" />
			<lb-date label="Range 1"  v-model="globalfilterObject.range1" :disabled="compareObjects" :error="globalfilterObjecterror.range1"/>
			<lb-date label="Range 2"  v-model="globalfilterObject.range2" :disabled="compareObjects" :error="globalfilterObjecterror.range2"  v-if="(globalfilterObject.operation === 'Between' && typeOffilter === 'Date')"/>
           </v-col>
         </v-row>
        </v-card-text>
    <v-card-actions class="py-4 px-6">
		<v-spacer></v-spacer>
			<v-btn small color="fbcPrimary" class="white--text" @click="reomveGobalefilter(selectcollection,selectkey,mathcFilter,matchTypeFilter)">
         <span>Clear Filter</span>
       </v-btn>
	<v-btn small color="fbcPrimary" class="white--text" @click="runfilter(globalfilterObject.operation,globalfilterObject.range,globalfilterObject.range1,globalfilterObject.range2,selectFilter,typeOffilter,selectcollection,selectkey,mathcFilter,matchTypeFilter)">
         <span>Add Filter</span> 
       </v-btn>
        </v-card-actions>
		<v-card-text>
			<v-simple-table dense class="FC-Table" v-if="filterArray.length > 0">
				<template v-slot:default>
				<thead>
					<tr>
					<th class="text-left ellipsis-on" id="sequence">
						Sequence
					</th>
					<th class="text-left ellipsis-on" id="column">
						Column
					</th>
					<th class="text-left ellipsis-on" id="operation">
						Operation
					</th>
					<th class="text-left ellipsis-on" id="range1">
						Criteria 1
					</th>
					<th class="text-left ellipsis-on" id="range2">
						Criteria 2
					</th>
					<th class="text-left" id="type">
						Type
					</th>
					<th class="text-left" id="action">
						Action
					</th>
					</tr>
				</thead>
				<tbody>
					<tr
					v-for="(item,index ) in filterArray"
					:key="index"
					:id="'automation-test-'+index"
					>
					<td :id="'automation-test-'+index">{{ index + 1 }}</td>
					<td :id="'automation-test-'+index">{{ item.column }}</td>
					<td :id="'automation-test-'+index">{{ item.operation }}</td>
					<td :id="'automation-test-'+index">{{ isValidDate(item.value1) }}</td>
					<td :id="'automation-test-'+index">{{ isValidDate(item.value2) }}</td>
					<td :id="'automation-test-'+index">{{ item.type }}</td>
					<td :id="'automation-test-'+index">  
					<v-btn icon small @click="deleteGobalefilter(filterArray,index,selectcollection,selectkey,mathcFilter,matchTypeFilter)"  color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn></td>
					</tr>
				</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
      </v-card>
    </v-dialog>



	<lb-dialog v-model="setupAIdilog" heading='AI Setup' width="700" :loading="setupAIdilogloading">
	<template v-slot:body>
		<div class="d-flex">
			<v-spacer></v-spacer>
			<v-btn color="primary" small @click="resetAI()">Reset</v-btn>
		</div>
		<div>
			<v-row>
			<v-col class="pt-0">
				<div class="body-2 font-weight-bold mt-4"> Column Mapping </div>
				<div class="mt-2">
					<v-simple-table dense>
					<thead>
					<tr>
						<th :style="`background-color:${colors[1]} !important`" scope="d1cols" class="text-center">
						{{ filenames[0] }}
						</th>
						<th scope="dtypes" class="text-center white"> Data Type </th>
						<th :style="`background-color:${colors[2]} !important`" scope="d2cols" class="text-center">
						{{ filenames[1] }}
						</th>
						<th class="text-center white"> Setup Values </th>
						<th class="white"> Action </th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(v, k) in copycolumns" :key="k" :id="'automation-test-'+k">
						<td class="text-center">{{ v.data1 }}</td>
						<td class="text-center">
						<v-chip small label>{{ v.type }}</v-chip>
						</td>
						<td class="text-center">{{ v.data2 }}</td>
						<td class="text-center" v-if="setupaiObj && setupaiObj[v.name]">
						<div v-if="v.type === 'string'" style="width: 150px">
							<lb-number hidedetails label="Match %" v-model="setupaiObj[v.name].matchpercentage"></lb-number>
						</div>
						<div v-else-if="v.type === 'number'" style="width: 150px">
							<lb-number hidedetails label="Match %" v-model="setupaiObj[v.name].matchpercentage"></lb-number>
							<v-checkbox dense hide-details class="ma-0 pa-0 mr-2" label="Negative value" v-model="setupaiObj[v.name].oppositesignmatch"></v-checkbox>
						</div>
						<div v-else-if="v.type === 'date'" style="width: 150px">
							<lb-number hidedetails label="Match %" v-model="setupaiObj[v.name].matchpercentage"></lb-number>
						</div>
						</td>
						<td>
						<v-btn icon small @click="deleteAIsetup(v.name,k)" color="error">
							<v-icon>mdi-delete</v-icon> 
						</v-btn>
						</td>
					</tr>
					</tbody>
				</v-simple-table>
				</div>
            </v-col>
			</v-row>
		</div>
	</template>
	<template v-slot:actions>
       <v-spacer></v-spacer>
       <v-btn color="primary" small @click="startRunAI(setupaiObj)">Run AI</v-btn>
	<v-btn color="primary ml-2" small @click="setupAIdilog = false">Cancel</v-btn>
     </template>
	</lb-dialog>


	
 </div>
</template>

<script>
// import FileUploads from '../../../../views/conserve/FileUploads.vue';
import FileUploadsOwn from './TransMatchingFileuploades.vue'
// import AiLoading from './AiLoading.vue'
export default {
	data() {
		return {
			e1: 1,
			backlink: "/financialclose/automationtransactionmatching/" + this.$route.params.id,
			botid: "6474e49c00b1f12f6a2a8065",
			project: "",
			loading: false,
			id: "",
			tab: 0,
			metadata: {},
			botproject: {},
			botdetails: {},
			setupList: {},
			verificationlist: [],
			stingingcheck:0,
			stageMassage:'',
			hasParameters: false,
			savedParameters: false,
			inputparametersvalues: {},
			uploaddetails: {},
			fileuploaddata: {},
			fileprocesserrors: {},
			fileuploadpercent: {},
			fileprocessstage: {},
			filesdata: {},
			processedFiles: false,
			savedFiles: false,
			hasFiles: false,
			rundetails: {},
			annexurelist: [],
			selectedresulttab: 0,
			selectedannexure: "",
			currentannexuredata: [],
			originalcurrentannexuredata: [],
			annexurenames: {},
			annexuredata: {},
			fileConfigInfo: {},
			rundetailsall:[],
			stagefilter: [],
			stagefilterNew: [],
			stagefilterAI:[],
			columns: {},
			copycolumns:{},
			filenames: [],
			colors: [],
			addeditdialog: false,
			addeditloading: false,
			addeditdatakey: {},
			addeditdata: {},
			addeditdatacols: {},
			selectedrulecolumn: [],
			aggregationtypesection: {},
			dataforfilter: [],
			formateddataforfilter: [],
			formateddataforfilterNew: [],
			originalFormatedData: [],
			originalFormatedDatatwo: [],
			searchvalue: "",
			searchvalueNew: "",
			collectionnames: {},
			comparedialog: false,
			compareloading: false,
			comparedialogNew: false,
			compareloadingNew: false,
			comparingitem: {},
			comparingitemNew: {},
			selectedids: [],
			selectedidsNew: [],
			filterselectkey: "",
			filterselectkeyNew: "",
			rulenaturelist: [{
					name: "Automated",
					value: "Automated"
				},
				{
					name: "Suggested",
					value: "Suggested"
				}
			],
			ruletypelist: [{
					name: "One to One(1:1)",
					value: "1:1"
				},
				{
					name: "One to Many(1:M)",
					value: "1:M"
				},
				{
					name: "Many to One(M:1)",
					value: "M:1"
				},
				{
					name: "Many to Many(M:M)",
					value: "M:M"
				},
			],
			strmatchtype: [{
					name: "Exact",
					value: "exact"
				},
				{
					name: "Contains",
					value: "contains"
				},
				{
					name: "Begins with",
					value: "begins with"
				},
				{
					name: "Ends with",
					value: "ends with"
				},
				{
					name: "Approximate Match",
					value: "approximate match"
				},
			],
			aggregationtype: [{
					name: "Mean",
					value: "mean"
				},
				{
					name: "Sum",
					value: "sum"
				},
				{
					name: "Min",
					value: "min"
				},
				{
					name: "Max",
					value: "max"
				},
				{
					name: "Count",
					value: "count"
				},
				{
					name: "Size",
					value: "size"
				},
				{
					name: "First",
					value: "first"
				},
				{
					name: "Last",
					value: "last"
				},
				{
					name: "Nunique",
					value: "nunique"
				},
				{
					name: "Standard Deviation",
					value: "std"
				},
				{
					name: "Variance",
					value: "var"
				},
				{
					name: "Median",
					value: "median"
				},
				{
					name: "Quantile",
					value: "quantile"
				},
			],
			rulesdata: [],
			rulesdataCopy:[],
			typeOfUser: 'Paid',
			recoincilationData: [],
			recoincilationHeaders: [{
					value: "description",
					text: "Description",
					sortable: true,
					filterable: true,
					lock: true,
					visible: false,
				},
				{
					value: "subamount",
					text: "Sub Amount",
					sortable: false,
					filterable: false,
					datatype: "number",
					default: "0",
					lock: true,
					visible: false,
					alignment: "text-center",
				},
				{
					value: "finalamount",
					text: "Final Amount",
					sortable: false,
					filterable: false,
					datatype: "number",
					default: "0",
					lock: true,
					visible: false,
					alignment: "text-center",
				},
				{
					value: "attachmentfile",
					text: "File Attachment(s)",
					sortable: false,
					filterable: false,
					datatype: "action",
					lock: true,
					visible: false,
					alignment: "text-center",
				},
				{
					value: "action",
					text: "Action",
					sortable: false,
					datatype: "action",
					lock: true,
					visible: false,
					alignment: "text-center",
				}
			],
			recoincilationDialogue: false,
			recoincilationItem: {},
			recoincilationErrorItem: {},
			headers: [{
					value: "indexno",
					text: "#",
					sortable: false,
					filterable: false,
				},
				{
					value: "tag",
					text: "Tag",
					sortable: true,
					filterable: true,
				},
				{
					value: "description",
					text: "Description",
					sortable: true,
					filterable: true,
				},
				{
					value: "operator",
					text: "Operator",
				},
				{
					value: "tableid",
					text: "Table",
				},
				{
					value: "column",
					text: "Column",
				},
				{
					value: "filterword",
					text: "Filterword",
				},
				{
					value: "action",
					text: "Action",
				},
			],
			fileMapshow: false,
			addfilterdialog: false,
			addfilterdialogloading: false,
			addfilterconfim:false,
			addfilterconfimloading:false,
			addrulesconfim:false,
			addrulesconfimloading:false,
			filterobject: {
				tableid: [],
				column: [],
				operator: [],
				description: undefined,
				excludetype: 'Exclude',
				tag: '',
				status: '',
				filterword: '',
			},
			filterrowType: ["Exclude", "Delete"],
			operatorType: ["Equal", "Contains", "Start With", "End With", "Not Equal", "Greater than", "Less than"],
			filtercolums: [],
			filterList: [],
			filterListCopy:[],
			formatedData: [],
			formatedDataMiddeleware: [],
			addMiddlewaredialog: false,
			addMiddlewaredialogloading: false,
			middlewareObject: {},
			middlewareHeader: [{
					value: "indexno",
					text: "#",
					sortable: false,
					filterable: false,
				},
				{
					value: "middlewarename",
					text: "Middleware",
					sortable: true,
					filterable: true,
				},
				{
					value: "basefile",
					text: "Base File",

				},

				{
					value: "action",
					text: "Action",
				},

			],
			middlewareValues: [],
			alaysisDetails: {},
			middFile: null,
			middlewareObjecterror: {},
			filterobjecterror: {},
			page: 1,
			pagecount: 1,
			perpage: 50,
			total: 0,
			pagenew: 1,
			pagecountnew: 1,
			perpagenew: 50,
			totalnew: 0,
			pagefifo: 1,
			pagecountfifo: 1,
			perpagefifo: 50,
			totalfifo: 0,
			draggedItemIndex: null,
			isEditing: false,
			currentIndex: null,
			resultTabItem: [{
				name: 'Summary',
				value: 'Summary'
			}, {
				name: 'Matched',
				value: 'Matched'
			}, {
				name: 'Un Matched',
				value: 'Unmatched'
			},{
				name: 'AI',
				value: 'AI',
				icon: 'mdi-settings'

			}],
			resultTab: 0,
			comboAnnexer: [],
			collectionNameList: [],
			newAnnexersdata: [],
			newannexerCombodata: [],
			selectedCombo: 0,
			filterCombo: '',
			RecoincilationTab: '',
			recoincilationSummaryData: [],
			recoincilationCollection: '',
			tabletype: 'Summary',
			originalAnnexersdata: [],
			selectiteamComparing: [],
			twofileselectiteamComparing: [],
			filtertwofile: '',
			filtermorefile: '',
			fifoalaysis: false,
			selectFifo: [],
			selectallfifo:false,
			analysisTypeItem: [{
					name: "Intercompany Reconciliation"
				},
				{
					name: "Accounts receivable Reconciliation"
				},
				{
					name: "Accounts payable Reconciliation"
				}
			],
			fifoannexData: [],
			electFifo: [],
			runfifoppop: false,
			executionbtn: false,
			executionbtnAI: false,
			fifoexecutionResult: [],
			aiexecutionResult:[],
			sortColumn: {},
			sortDesc: false,
			selectFilter: '',
			selectfilterdialog: false,
			selectfilterdialogloading: false,
			globalfilterObject: {
				operation: '',
				range: '',
				range1: '',
				range2: ''
			},
			globalfilterObjecterror: {
				operation: '',
				range: '',
				range1: '',
				range2: '',
				tag:'',
			},
			typeOffilter: '',
			filteritemNumber: ["Equals", "Does Not Equals", "Greater Then", "Greater Then Or Equal To", "Less Then", "Less Then Or Equal To", "Between"],
			filteritemString: ["Equals", "Does Not Equals", "Contains", "Does Not Contains"],
			filteritemDate: ["Equals", "Before", "After", "Between"],
			filterArray: [],
			globalArray: [],
			selectcollection: '',
			selectkey:'',
			oneormorefile:false,
			selectedCombotwo:0,
			selectedcollection:'',
			setupAIdilog:false,
			setupAIdilogloading:false,
			setupaiObj:{},
			ailoadingimage:false,
			matchCollection:'',
			mathcFilter:'KKK',
			matchTypeFilter:'JJJ',
		};
	},
	components: {
		// FileUploads,
		FileUploadsOwn,
		// AiLoading
	},
	created() {
		this.refreshData();
	},
	activated() {
		this.refreshData();
	},
	methods: {
		init() {
			if (this.$store.state.subscriptions.financialclose.plan_detail.name) {
				this.typeOfUser = this.$store.state.subscriptions.financialclose.plan_detail.name || "Paid";
			}
		},
		refreshData() {
			this.init();
			this.id = this.$route.params.id || "";
			this.userid = this.$store.state.user.id;
			this.anaid = this.$route.params.anaid;
			this.currentannexuredata = [];
			this.annexuredata = {};
			this.getData();
		},
		getData() {
			this.loading = true;
			this.project = this.$route.params.id;
			return this.axios.post("/v2/financialclose/analysis/getbotdetails/" + this.id).then(dt => {
				if (dt.data.status === "success" && dt.data.data.length > 0) {
					let data = dt.data.data[0] || {}
					this.metadata = data.metadata || {};
					this.stage = this.metadata.stage;
					this.resultTab = 0;
					this.tabletype = 'Summary';
					this.selectedannexure = '';
					this.executionbtnAI = false;
				}
			}).catch(err => {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
			}).finally(() => {
				this.loading = false;
				this.getTransctionmatching();
				this.loadBot();
				this.getRecoincialtionData();
			})
		},
		formateData() {
			let ObjectTemp = {};
			let ArrayTemp = [];
			this.filterList.forEach((item, index) => {
				ObjectTemp = {
					indexno: index,
					_id: item._id,
					description: item.description,
					excludetype: item.excludetype,
					operator: item.operator,
					tableid: item.tableid,
					tag: item.tag,
					column: item.column,
					filterword: item.filterword,
					status: item.status,
				};
				ArrayTemp.push(ObjectTemp);
			});
			this.formatedData = ArrayTemp;
		},
		formatemiddlewareData() {
			let ObjectTemp = {};
			let ArrayTemp = [];
			this.middlewareValues.forEach((item, index) => {
				ObjectTemp = {
					indexno: index,
					_id: item._id,
					middlewarename: item.name,
					basefile: item.basefile,
					status: item.status,
					middlewarefile: item.middlewarefile
				};
				ArrayTemp.push(ObjectTemp);
			});
			this.formatedDataMiddeleware = ArrayTemp;
		},
		gettableName(id) {
			let files = this.setupList.file.find((x) => x._id === id);
			return (files || {}).name;
		},
		checkChangesRules(){
			if (this.rulesdata.length !== this.rulesdataCopy.length || JSON.stringify(this.rulesdata) !== JSON.stringify(this.rulesdataCopy)) {
			this.addrulesconfim = true;
			} else {
			this.e1 = 3;
			
			}
		},
		checkChangesFilter() {
			if (this.filterList.length !== this.filterListCopy.length || JSON.stringify(this.filterList) !== JSON.stringify(this.filterListCopy)) {
			this.addfilterconfim = true;
			} else {
			this.e1 = 3;
			}
		},
		getfilterlistCancel(){
			this.filterList = [...this.filterListCopy];
			this.formateData();
			this.addfilterconfim = false;
		},
		getruleslistCancel(){
			this.rulesdata = [...this.rulesdataCopy];
			this.addrulesconfim = false;
		},
		async getfilterlist() {
			if (!this.alaysisDetails || !this.alaysisDetails.pid || !this.alaysisDetails._id || !this.alaysisDetails.bpid) {
				console.error("Missing necessary analysis details");
				return;
			}
			this.loading = true;
			try {
				let response = await this.axios.post("/v2/financialclose/analysis/getTmFilter/" + this.alaysisDetails.pid + '/' + this.alaysisDetails._id + '/' + this.alaysisDetails.bpid);
				if (response.data.status === "success" && response.data.data.length > 0) {
					this.filterList = response.data.data;
					this.filterListCopy = JSON.parse(JSON.stringify(response.data.data)); 
					this.formateData();
				}
			} catch (err) {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
			} finally {
				this.loading = false;
			}
		},
		async getmiddlewarelist() {
			if (!this.alaysisDetails || !this.alaysisDetails.pid || !this.alaysisDetails._id || !this.alaysisDetails.bpid) {
				console.error("Missing necessary analysis details");
				return;
			}
			this.loading = true;
			try {
				let response = await this.axios.post("/v2/financialclose/analysis/getTmMiddleware/" + this.alaysisDetails.pid + '/' + this.alaysisDetails._id + '/' + this.alaysisDetails.bpid);
				if (response.data.status === "success" && response.data.data.length > 0) {
					this.middlewareValues = response.data.data;
					this.formatemiddlewareData();
				}
			} catch (err) {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
			} finally {
				this.loading = false;
			}
		},
		async getTransctionmatching() {
			this.loading = true;
			try {
				let response = await this.axios.post("/v2/financialclose/analysis/getTransactionMatchingAnalysis/" + this.id + '/' + this.anaid);
				if (response.data.status === "success" && response.data.data.length > 0) {
					this.alaysisDetails = response.data.data[0];
					this.fifoalaysis = this.fifocheck(this.alaysisDetails.typeofanalysis);
					this.getfilterlist();
					this.getmiddlewarelist();
				}
			} catch (err) {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
			} finally {
				this.loading = false;
			}
		},
		fifocheck(item) {
			return this.analysisTypeItem.some(x => x.name === item);
		},
		openMatchDialog(d) {
			this.comparingitem = {
				...d
			};
			this.twofileselectiteamComparing = [this.comparingitem];
			// this.comparedialog = true;
			this.selectedids = [];
			this.searchvalue = "";
			this.getMatchdata();
			// this.$nextTick(() => {
			// 	this.filterMatchItems();
			// })
		},
		getMatchdata(){
			this.comparedialog = true;
			let findtableobjecr = {};
			if (this.selectedannexure.includes("table1unmatche")) {
				
				findtableobjecr = this.collectionNameList.find((x) => x.collection.includes('table2unmatched'));
				this.filterselectkey = "Uni_Code_df2"
			} else {
				
				findtableobjecr = this.collectionNameList.find((x) => x.collection.includes('table1unmatched'));
				this.filterselectkey= "Uni_Code_df1"
			}
			
			this.compareloading = true;
			let verification = this.verificationlist[0];
			let colloection = findtableobjecr.collection;
			this.selectedannexure = colloection;
			this.matchCollection = colloection;
			this.axios
				.post("/v2/bot/analysis/readannexure/" + this.alaysisDetails.bpid + "/c/" + colloection + "/v/" + verification._id)
				.then((dt) => {
					this.formateddataforfilter = dt.data.data;
					this.originalFormatedDatatwo = this.formateddataforfilter;
				}).finally(() => {
					this.compareloading = false;
				});
		},
		filterMatchItemsNewfilterTwo() {
			this.compareloadingNew = true;
			let sv = (this.searchvalue || "").toString().toLowerCase(); 
			let ndt = [];

			if (sv) {
				for (const i of this.originalFormatedDatatwo) {
					if (JSON.stringify(i || {}).toLowerCase().includes(sv)) {
						ndt.push(i);
					}
				}
				this.formateddataforfilter = ndt;
			} else {
				this.formateddataforfilter= [...this.originalFormatedDatatwo];
			}

			this.compareloadingNew = false;
		},
		// filterMatchItems() {
		// 	this.compareloading = true;
		// 	this.selectedids = [];
		// 	let sv = (this.searchvalue || "").toString();
		// 	let ndt = [];
		// 	if (sv) {
		// 		for (const i of this.dataforfilter) {
		// 			if (JSON.stringify(i || {}).toLowerCase().includes(sv)) ndt.push(i);
		// 		}
		// 		this.formateddataforfilter = ndt;
		// 	} else this.formateddataforfilter = this.dataforfilter;
		// 	this.compareloading = false;
		// },
		openMatchDialogNew(d) {
			this.comparingitemNew = {
				...d
			};
			this.selectiteamComparing = [this.comparingitemNew]
			this.comparedialogNew = true;
			this.selectedidsNew = [];
			this.searchvalueNew = "";
			this.filterMatchItemsNew();
		},
		filterMatchItemsNewfilter() {
			this.compareloadingNew = true;
			let sv = (this.searchvalueNew || "").toString().toLowerCase(); // Convert to lowercase here
			let ndt = [];

			if (sv) {
				for (const i of this.originalFormatedData) { // Use the original data to filter
					if (JSON.stringify(i || {}).toLowerCase().includes(sv)) {
						ndt.push(i);
					}
				}
				this.formateddataforfilterNew = ndt;
			} else {
				// If the search value is empty, restore the original data
				this.formateddataforfilterNew = [...this.originalFormatedData];
			}

			this.compareloadingNew = false;
		},
		filterMatchItemsNew() {
			const value = Object.values(this.newannexerCombodata[this.selectedCombo]);
			let findtable = {};
			if (value.some(item => item.includes('table1unmatched'))) {
				findtable = this.collectionNameList.find((x) => x.collection.includes('table2unmatched'));
				this.filterselectkeyNew = "Uni_Code_df2"
				
				
			} else {
				findtable = this.collectionNameList.find((x) => x.collection.includes('table1unmatched'));
				this.filterselectkeyNew = "Uni_Code_df1"
				
			}

			this.compareloadingNew = true;
			let verification = this.verificationlist[0];
			let colloection = findtable.collection;
			this.matchCollection = colloection;
			this.axios
				.post("/v2/bot/analysis/readannexure/" + this.alaysisDetails.bpid + "/c/" + colloection + "/v/" + verification._id)
				.then((dt) => {
					this.formateddataforfilterNew = dt.data.data;
					this.originalFormatedData = this.formateddataforfilterNew;
				}).finally(() => {
					this.compareloadingNew = false;
				});
		},
		fifiCollection() {
			let selectedverification = this.verificationlist[0] || {};
			let rundata = this.rundetails[selectedverification._id] || {};
			this.comboAnnexer = rundata.annexure || [];
			if (this.comboAnnexer.length > 0) {
				this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("Unmatched"));
				this.fifofetching(this.collectionNameList, selectedverification._id)
			} else {
				this.$store.commit("sbError", "Analysis has not yet been run");
			}
		},
		fifofetching(c, v) {
			this.loading = true;
			let data = {
				annexure1: {
					bid: this.botdetails._id,
					collection: c[0].collection,
					verification: v
				},
				annexure2: {
					bid: this.botdetails._id,
					collection: c[1].collection,
					verification: v
				}
			}
			this.axios
				.post("/v2/bot/analysis/fifo", {
					data
				})
				.then((ele) => {
					if (ele.data.status === "success") {
						this.fifoannexData = ele.data.data;
						this.loadBot();
					} else {
						this.$store.commit("sbError", ele.data.message || "Unknown error!");
					}
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		annexergenerateAll(filetype){
			let selectedverification = this.verificationlist[0] || {};
			let rundata = this.rundetails[selectedverification._id] || {};
			if (this.rundetailsall.length === 0) {
				return this.$store.commit("sbError", "The Analysis Has Not Been Run Yet");
			}
			
			if (rundata.stage === 2 && rundata.annexure.length === 0) {
				return this.$store.commit("sbError", rundata.message);
			} else {
				this.comboAnnexer = rundata.annexure || [];
				if (filetype === "Two File") {
				this.tabletype = 'Summary';
				this.resultTab = 0;
				this.collectionNameList = this.comboAnnexer.filter(item => item.collection && item.collection.includes('annexure_summary'));
				this.twoFileannexureSummary(this.collectionNameList[0].collection, selectedverification._id);
			} else {
				this.tabletype = 'Summary';
				this.collectionNameList = this.comboAnnexer.filter(item => item.collection && item.collection.includes('annexure_summary'));
				this.generateAnnexurelistSummery(this.collectionNameList[0].collection, selectedverification._id)
			}
			}


				
		},
		// annexerGen(filetype, selecttype, indextype) {
		// 	this.resultTab = 0;
		// 	this.filtermorefile = '';
		// 	if (filetype === 'Two File') {
		// 		this.generateAnnexurelist(selecttype);
		// 	} else {
		// 		if (indextype === 0) {
		// 			let selectedverification = this.verificationlist[0] || {};
		// 			let rundata = this.rundetails[selectedverification._id] || {};
		// 			this.comboAnnexer = rundata.annexure || [];
		// 			if (this.comboAnnexer.length > 0) {
		// 				this.collectionNameList = this.comboAnnexer.filter(item => item.name === "Summary");
		// 				this.tabletype = 'Summary';
		// 				this.generateAnnexurelistSummery(this.collectionNameList[0].collection, selectedverification._id)
		// 			} else {
		// 				this.$store.commit("sbError", "Analysis has not yet been run");
		// 			}
		// 		}
		// 	}
		// },
		collectionGet(type, tab){
			
			
			let aiCollection = '';
			let selectedverification = this.verificationlist[0] || {};
			let rundata = this.rundetails[selectedverification._id] || {};
			
			if (rundata.stage === 2 && rundata.annexure.length === 0) {
				this.$store.commit("sbError", rundata.message);
			} else {
				this.comboAnnexer = rundata.annexure || [];
				switch (type) {
				case 'Summary':
					this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("Summary"));
					this.tabletype = 'Summary';
					break;
				case 'Matched':
					this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("Matched"));
					this.tabletype = 'Matched';
					this.filterselectkey = "Uni_Code_df1"
					break;
				case 'Unmatched':
					this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("Unmatched"));
					this.tabletype = 'Unmatched';
					this.filterselectkey = "Uni_Code_df2"
					break;
				case 'AI':
					this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("AI"));
					this.tabletype = 'AI';
					break;
				default:
					this.collectionNameList = this.comboAnnexer.filter(item => item.name === "Summary");
					this.tabletype = 'Summary';
				}
				if (type === 'Summary' && tab === 0) {
					this.selectedcollection = this.collectionNameList[0].collection;
					this.twoFileannexureSummary(this.collectionNameList[0].collection, selectedverification._id)
				} else {
					const hasNoDataMessages = this.collectionNameList.some(item => item.message === "no data found in annexure");
					if (!hasNoDataMessages) {
					if (this.tabletype === 'AI') {
						aiCollection = this.collectionNameList.find((x) => x.collection.includes('annexure_AI'));
						if (aiCollection) {
							this.generateAnnexurelistAI(aiCollection.collection,selectedverification._id);
						} else{
							this.$store.commit("sbError",  "AI Annexure Not Generated");
							this.newAnnexersdata = [];
							this.currentannexuredata = [];
						}
					} else {
					this.selectedCombotwo = 0;
					this.selectedcollection = this.collectionNameList[0].collection;
					this.twoFileannexureSummary(this.collectionNameList[0].collection, selectedverification._id)
					}
					
					} else {
					this.selectedCombotwo = '';
					this.currentannexuredata = [];
					return this.$store.commit("sbError", 'No data found in annexure');
					}

					
				}
			}
		},
		comboCollectionGet(type, tab) {
			let aiCollection = '';
			this.filtermorefile = '';
			let selectedverification = this.verificationlist[0] || {};
			let rundata = this.rundetails[selectedverification._id] || {};
			this.comboAnnexer = rundata.annexure || [];
			switch (type) {
				case 'Summary':
					this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("Summary"));
					this.tabletype = 'Summary';
					break;
				case 'Matched':
					this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("Matched"));
					this.tabletype = 'Matched';
					break;
				case 'Unmatched':
					this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("Unmatched"));
					this.tabletype = 'Unmatched';
					break;
				case 'AI':
					this.collectionNameList = this.comboAnnexer.filter(item => item.name.includes("AI"));
					this.tabletype = 'AI';
					break;
				default:
					this.collectionNameList = this.comboAnnexer.filter(item => item.name === "Summary");
					this.tabletype = 'Summary';
			}

			if (type === 'Summary' && tab === 0) {
				this.generateAnnexurelistSummery(this.collectionNameList[0].collection, selectedverification._id)
			} else {
				const hasNoDataMessage = this.collectionNameList.some(item => item.message === "no data found in annexure");
				if (!hasNoDataMessage) {
					if (this.tabletype === 'AI') {
						aiCollection = this.collectionNameList.find((x) => x.collection.includes('annexure_AI'));
						if (aiCollection) {
							this.generateAnnexurelistAI(aiCollection,selectedverification._id);
							
						} else{
							this.$store.commit("sbError",  "AI Annexure Not Generated");
							this.newAnnexersdata = [];
						}
					} else {
						this.generateAnnexurelistCompo(this.collectionNameList, 1)
					}
				} else {
					this.newAnnexersdata = [];
					this.newannexerCombodata = [];
					return this.$store.commit("sbError", 'No data found in annexure');
				}
			}
		},
		selecttwofilefun(name,collection){
			let selectedverification = this.verificationlist[0] || {};
			this.selectedcollection = collection;
			this.twoFileannexureSummary(collection,selectedverification._id)
			this.globalfilterpopClose();
			
		},
		generateAnnexurelistSummery(collection, id) {	
			this.selectedannexure = collection;
			this.ailoadingimage = true;
			this.axios.post("/v2/bot/analysis/readannexure/" + this.alaysisDetails.bpid + "/c/" + collection + "/v/" + id)
				.then((dt) => {
					if (dt.data.status === "success") {
						this.newAnnexersdata = this.indexOrder(dt.data.data);
						this.originalAnnexersdata = [...this.newAnnexersdata];
						this.newannexerCombodata = [];
					} else { throw new Error(dt.data.message || "Error Get Combo Code") ; }
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.ailoadingimage = false;
				});
		},
		generateAnnexurelistAI(collection,id){
			this.selectedannexure = collection;
			this.ailoadingimage = true;
			this.axios.post("/v2/bot/analysis/readannexure/" + this.alaysisDetails.bpid + "/c/" + collection + "/v/" + id)
				.then((dt) => {
					if (dt.data.status === "success") {
					if (this.alaysisDetails.filecount === 'Two File') {
							this.currentannexuredata = dt.data.data;
						} else {
							this.newAnnexersdata = dt.data.data;
						}
					} else { 
						this.newAnnexersdata = [];
						this.currentannexuredata = [];
						throw new Error(dt.data.message || "Error Get AI") ; 
					}
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.ailoadingimage = false;
				});
		},
		async generateAnnexurelistCompo(collectionname, slectcolection) {
			const collectionArray = Array.isArray(collectionname) ? collectionname : [collectionname];
			this.ailoadingimage = true;
			try {
				const apiCalls = collectionArray.map(async (item) => {
					if (!item?.collection) {
						throw new Error("Invalid item in collectionname");
					}
					const data = {
						collection: item.collection
					};
					try {
						const response = await this.axios.post("/v2/financialclose/analysis/getdatabycombocode", {
							data
						});
						if (response.data.status === "success") {
							return response.data.data;
						} else {
							throw new Error(response.data.message || "Error Get Combo Code");
						}
					} catch (err) {
						this.$store.commit("sbError", err.message || "Unknown error!");
						this.ailoadingimage = false;
						console.error(err);
						return [];
					}
				});
				const results = await Promise.all(apiCalls);
				this.newannexerCombodata = results.flat();
				if (this.newannexerCombodata.length > 0) {
					this.selectedCombo = slectcolection;
					this.getcomboList(this.selectedCombo);

				} else {
					this.newAnnexersdata = [];
					this.loading = false;
				}
				
			} catch (err) {
				this.$store.commit("sbError", err.message || "Unknown error!");
				console.error(err);
				this.newAnnexersdata = [];
				this.ailoadingimage = false;
			} finally {
				this.ailoadingimage = false;
			}
		},
		twoFileannexureSummary(collection,id){
			this.selectedannexure = collection;
			this.ailoadingimage = true;
			const filter = {
				nolimit: true,
			};
			this.axios.post("/v2/bot/analysis/readannexure/" + this.alaysisDetails.bpid + "/c/" + collection + "/v/" + id,{filter})
				.then((dt) => {
					if (dt.data.status === "success") {
						this.currentannexuredata = this.indexOrder(dt.data.data);
						this.originalcurrentannexuredata = [...this.currentannexuredata]
					} else  { 
						this.newAnnexersdata  = [];
						this.currentannexuredata  = [];
						throw new Error(dt.data.message || "Error Get Combo Code"); 
						}
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.ailoadingimage = false;
				});
		},
		generateAnnexurelist: function(index = 0) {
			let ndata = [];
			let selectedverification = this.verificationlist[index] || {};
			let rundata = this.rundetails[selectedverification._id] || {};
			this.annexurenames = {};
			if (Object.keys(rundata).length > 0) {
				let annxdata = rundata;
				let hasannexure = false;
				for (let i = 0; i < (annxdata.annexure || []).length; i++) {
					const el = (annxdata.annexure || [])[i];
					ndata.push({
						name: el.name,
						value: el.collection,
						displayName: el.displayName,

					});
					this.annexurenames[el.collection] = el.name;
					if (el.collection === this.selectedannexure) hasannexure = true;
				}
				if (!hasannexure) this.selectedannexure = ndata[0]?.value;
				this.readAnnexure(this.selectedannexure, selectedverification._id);
			}
			this.annexurelist = ndata;
		},
		readAnnexure(c, v) {
			this.filtertwofile = '';
			if (c) {
				this.selectedannexure = c;
				let filterannexure = "";
				this.filterselectkey = "";
				if (this.annexurenames[this.selectedannexure] === 'Table 1 Unmatched') {
					filterannexure = this.collectionnames["Table 2 Unmatched"];
					this.filterselectkey = "Uni_Code_df2"
				} else if (this.annexurenames[this.selectedannexure] === 'Table 2 Unmatched') {
					filterannexure = this.collectionnames["Table 1 Unmatched"];
					this.filterselectkey = "Uni_Code_df1"

				}
				this.loading = true;
				const filter = {
					nolimit: true,
				};
				Promise.resolve()
					.then(() => {
						return this.axios.post("/v2/bot/analysis/readannexure/" + this.alaysisDetails.bpid + "/c/" + c + "/v/" + v, {
							filter
						});
					})
					.then((dt) => {
						if (dt.data.status === "success") {
							this.pageinationEmpty();
							this.loading = false;
							this.annexuredata[c] = dt.data.data;
							this.currentannexuredata = this.annexuredata[c];
							if (filterannexure) return this.axios.post("/v2/bot/analysis/readannexure/" + this.alaysisDetails.bpid + "/c/" + filterannexure + "/v/" + v, {
								filter
							});
						} else throw new Error(dt.data.message || "Error reading annexure");
					})
					.then((dt) => {
						if (filterannexure) {
							if (dt.data.status === "success") {
								this.dataforfilter = dt.data.data;
								this.selectedids = [];
							} else throw new Error(dt.data.message || "Error reading annexure");
						}
					})
					.catch((err) => {
						this.$store.commit("sbError", err.message || err || "Unknown error!");
						this.currentannexuredata = [];
						console.log(err);
					})
					.finally(() => {
						this.globalfilterpopClose();
						this.loading = false;
					});
				// }
			}
		},
		loadBot() {
			this.loading = true;
			this.selectFifo = [];
			return this.axios.post("/v2/financialclose/analysis/loadTransactionMatchingAnalysis/" + this.anaid, {
				data: {
					pid: this.id,
					bid: this.botid,
				}
			}).then(dt => {
				if (dt.data.status === "success" && dt.data.data.length > 0) {
					let data = dt.data.data[0] || {};
					this.botdetails = data.metadata || {};
					this.uploaddetails = data.uploaddetails || {};
					this.setupList = data.setup || {};
					this.verificationlist = this.setupList.verification || [];
					this.inputparametersvalues = this.botdetails.inputparameter || {};
					this.fileConfigInfo = this.setupList?.fileconfig || {};
					this.rundetailsall = data.rundetails;
					
					
					this.rundetails = {};
					for (const i of data.rundetails || []) {
						this.rundetails[i.verification] = i;
					}
					let selectedverification = this.verificationlist[0] || {};
					this.stingingcheck = (this.rundetails[selectedverification._id] || {}).stage;
					console.log((this.rundetails[selectedverification._id] || {}),"(this.rundetails[selectedverification._id] || {})");
					this.stageMassage = (this.rundetails[selectedverification._id] || {}).message;
					console.log(this.stingingcheck,"stingingcheck");
					this.generateAnnexurelist();
					this.collectionnames = {};
					for (const k in this.annexurenames) {
						if (Object.hasOwnProperty.call(this.annexurenames, k)) {
							const el = this.annexurenames[k];
							this.collectionnames[el] = k
						}
					}
					// to check if inputparameter are available and if the same is saved
					this.savedParameters = false;
					if ((this.setupList.parameter || []).length > 0) {
						this.hasParameters = true;
						let allparametersaved = true;
						for (let ele of this.setupList.parameter) {
							if (!Object.hasOwnProperty.call((this.botdetails.inputparameter || {}), ele._id)) {
								allparametersaved = false;
							}
						}
						this.savedParameters = allparametersaved;
					} else {
						this.hasParameters = false;
					}

					// set default input parameter values
					let availableids = [];
					for (const k in this.inputparametersvalues) {
						if (Object.hasOwnProperty.call(this.inputparametersvalues, k)) {
							availableids.push(k);
						}
					}
					for (const i of this.setupList.parameter || []) {
						if (availableids.indexOf(i._id) === -1) {
							if (
								Object.hasOwnProperty.call(
									this.setupList.inputparameterconfig || {},
									i._id
								)
							)
								this.inputparametersvalues[i._id] = (
									this.setupList.inputparameterconfig[i._id] || {}
								).value;
							else this.inputparametersvalues[i._id] = i?.value;
						}
					}

					//to check if files are available and if the same is saved and also flag processing errors
					// if ((this.setupList.file || []).length > 0) {
					// 	this.hasFiles = true;
					// 	let allfileprocessed = true;
					// 	let allfilesaved = true;
					// 	let availablefiles = [];
					// 	for (const i of this.botdetails.files || []) {
					// 		availablefiles.push(i.fileid);
					// 	}
					// 	for (let ele of this.setupList.file) {
					// 		let id = ele._id;
					// 		if ((this.uploaddetails[id] || {}).processed !== 1) {
					// 			allfileprocessed = false;
					// 		}
					// 		if ((this.uploaddetails[id] || {}).processed === -1) this.fileprocesserrors[id] = "Error while processing file";
					// 		if (availablefiles.indexOf(id) === -1 || (this.uploaddetails[id].processed || 0) === -1) allfilesaved = false;
					// 	}
					// 	this.savedFiles = allfilesaved;
					// 	this.processedFiles = allfileprocessed;
					// } else {
					// 	this.hasFiles = false;
					// }
					if (this.inputparametersvalues) {
						const firstKey = Object.keys(this.inputparametersvalues)[0];
						this.rulesdata = (this.inputparametersvalues[firstKey] || []).filter(x => x != null);
						this.rulesdataCopy  = JSON.parse(JSON.stringify(this.rulesdata));

					} else {
						this.rulesdata = [];
					}

					this.loadColumns(this.setupList);
				}
			}).catch(err => {
				console.log(err);
				this.$store.commit("sbError", err.message || err || "Unknown error!");
			}).finally(() => {
				this.loading = false;
			})
		},
		openAddEditRuleDialog(d = {}, type = "add", k) {
			this.addeditdata = {
				type: "1:1"
			};
			this.addeditdatacols = {};
			this.aggregationtypesection = {};
			this.copycolumns = Object.values(this.columns);
			for (const k in this.columns) {
				if (Object.hasOwnProperty.call(this.columns, k)) {
					// const el = this.columns[k];
					this.addeditdatacols[k] = {
						type: null,
						negative: false,
					};
				}
			}
			this.selectedrulecolumn = [];
			this.aggregationtypesection = {};
			if (type === "add") {
				this.addeditdata.nature = "Automated";
				this.addeditdatakey = (this.rulesdata.length || 0) + 1;
			} else {
				this.addeditdata = {
					...this.addeditdata,
					...d
				};
				delete this.addeditdata?.cols;
				this.addeditdatacols = {
					...this.addeditdatacols,
					...d.cols
				};
				this.addeditdatakey = k;
				for (const k in d.cols) {
					if (Object.hasOwnProperty.call(d.cols, k)) {
						// const el = this.addeditdatacols[k];
						this.selectedrulecolumn.push(k);
					}
				}
				for (let i = 0; i < (d.agg_cols || []).length; i++) {
					const el = d.agg_cols[i];
					this.aggregationtypesection[el] = d.agg_type[i];
				}
			}
			this.addeditdata.__type = type;
			this.addeditdialog = true;
			this.copycolumns = Object.values(this.columns);
		},
		updateRule(type) {
			let dt = {
				...this.addeditdata
			};
			let colsdt = {
				...this.addeditdatacols
			};
			let newcoldt = {}
			let groupcols = [];
			for (const k in colsdt) {
				if (Object.hasOwnProperty.call(colsdt, k)) {
					const el = colsdt[k];
					if (this.selectedrulecolumn.indexOf(k) > -1) {
						newcoldt[k] = el;
						if (this.columns[k].type === 'string' || this.columns[k].type === 'date') groupcols.push(k)
					}
				}
			}
			if (dt.type === "1:M" || dt.type === "M:1" || dt.type === "M:M") {
				dt.group_by = groupcols;
				dt.agg_cols = [];
				dt.agg_type = [];
				for (const k in this.aggregationtypesection) {
					if (Object.hasOwnProperty.call(this.aggregationtypesection, k)) {
						const el = this.aggregationtypesection[k];
						if (el) {
							dt.agg_cols.push(k);
							dt.agg_type.push(el);
						}
					}
				}
			}
			this.copycolumns = Object.values(this.columns);
			
			dt.cols = newcoldt;
			if (Object.keys(newcoldt).length === 0) {
				return  this.$store.commit("sbError", "Add at least one rule ")
			}
			delete dt.__type;
			if (type === "add") {
				dt.number = (this.addeditdatakey || "").toString();	
				this.rulesdata.push(dt);
			} else if (type === "edit") {
				dt.number = this.addeditdata.number;
				this.rulesdata[this.addeditdatakey] = dt;
			}
			this.addeditdialog = false;
		},
		async startRun() {
			this.loading = true;
			let data = {
				isittm: true,
				tmaid: this.alaysisDetails._id,
				toprocess: this.alaysisDetails.to_process
			}
			await this.axios
				.post("/v2/bot/analysis/startrun/" + this.alaysisDetails.bpid, {
					data
				})
				.then((ele) => {
					if (ele.data.status === "success") {
						this.$store.commit("sbSuccess", "Run Started");
						this.loadBot();
					} else throw new Error("Error starting run");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		loadColumns(dt) {
			let tcols = {};
			let dcount = 1;
			for (const i of dt.file) {
				this.filenames[dcount - 1] = i.name || ("Data " + dcount + " Columns");
				for (const j of i.columns) {
					tcols[j.name] = j;
				}
				dcount++;
			}
			dcount = 1;
			for (const j in dt.fileconfig) {
				if (Object.hasOwnProperty.call(dt.fileconfig, j)) {
					const el = dt.fileconfig[j];
					for (const k of el.columnmap || []) {
						tcols[k[0]]["data" + dcount] = k[1];
					}
					dcount++;
				}
			}
			this.columns = {};
			for (const k in tcols) {
				if (Object.hasOwnProperty.call(tcols, k)) {
					const el = tcols[k];
					if (el.data1 && el.data2) this.columns[k] = el;
				}
			}
			this.copycolumns = Object.values(this.columns);
		},
		initializeSetupAiObj() {
	if (!this.setupaiObj || typeof this.setupaiObj !== 'object') {
	this.setupaiObj = {};
    }
	this.setupaiObj = {};
      Object.values(this.columns).forEach((column) => {
         if (column.type === "string") {
          this.$set(this.setupaiObj, column.name, { matchpercentage: 80 }); // Default for string
        } else if (column.type === "number") {
          this.$set(this.setupaiObj, column.name,{ matchpercentage: 5, oppositesignmatch: false }); // Default for number
        } else if (column.type === "date") {
          this.$set(this.setupaiObj, column.name, { matchpercentage: 30, }); // Default for date
        }
      });
    },
		saveInputParameters() {
			this.loading = true;
			let ipval = {};
			ipval[Object.keys(this.inputparametersvalues)[0]] = this.rulesdata;
			let objectInput = {
				inputparameter: ipval
			};
			this.axios
				.post("/v2/bot/analysis/storeinputparameter/" + this.alaysisDetails.bpid, {
					data: objectInput,
				})
				.then((ele) => {
					if (ele.data.status === "success") {
						this.$store.commit("sbSuccess", "Input Parameters Updated");
						// this.$emit('reload');
						this.refreshData();
					} else throw new Error("Error updating input parameters");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		moveTransaction(d, type) {
			let ndt = {}
			this.compareloading = true;
			let summarycollection = this.collectionnames["Summary"];
			let allanexures = {
				"a": this.collectionnames["Table 1 Matched"],
				"b": this.collectionnames["Table 2 Matched"],
				"c": this.collectionnames["Table 1 Unmatched"],
				"d": this.collectionnames["Table 2 Unmatched"],
			}
			if (this.annexurenames[this.selectedannexure] === 'Table 1 Matched') {
				if (type === "approve") {
					ndt = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							mycol: "Uni_Code_df1",
							countercol: "Uni_Code_df2",
							summarycollection: summarycollection,
							fromcollection1: this.selectedannexure,
							fromcollection2: this.collectionnames["Table 2 Matched"]
						}
					}
				} else if (type === "reject") {
					ndt = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							mycol: "Uni_Code_df1",
							countercol: "Uni_Code_df2",
							summarycollection: summarycollection,
							fromcollection1: this.selectedannexure,
							tocollection1: this.collectionnames["Table 1 Unmatched"],
							fromcollection2: this.collectionnames["Table 2 Matched"],
							tocollection2: this.collectionnames["Table 2 Unmatched"]
						}
					}
				}
			} else if (this.annexurenames[this.selectedannexure] === 'Table 2 Matched') {
				if (type === "approve") {
					ndt = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							mycol: "Uni_Code_df2",
							countercol: "Uni_Code_df1",
							summarycollection: summarycollection,
							fromcollection1: this.selectedannexure,
							fromcollection2: this.collectionnames["Table 1 Matched"]
						}
					}
				} else if (type === "reject") {
					ndt = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							mycol: "Uni_Code_df2",
							countercol: "Uni_Code_df1",
							summarycollection: summarycollection,
							fromcollection2: this.collectionnames["Table 1 Matched"],
							tocollection2: this.collectionnames["Table 1 Unmatched"],
							fromcollection1: this.selectedannexure,
							tocollection1: this.collectionnames["Table 2 Unmatched"]
						}
					}
				}
			} else if (this.annexurenames[this.selectedannexure] === 'Table 1 Unmatched') {
				if (type === "match") {
					ndt = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							countercodes: this.selectedids,
							mycol: "Uni_Code_df2",
							countercol: "Uni_Code_df1",
							summarycollection: summarycollection,
							fromcollection1: this.collectionnames["Table 2 Unmatched"],
							fromcollection2: this.selectedannexure,
							tocollection1: this.collectionnames["Table 2 Matched"],
							tocollection2: this.collectionnames["Table 1 Matched"]
						}
					}
				}
			} else if (this.annexurenames[this.selectedannexure] === 'Table 2 Unmatched') {
				if (type === "match") {
					ndt = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							countercodes: this.selectedids,
							mycol: "Uni_Code_df1",
							countercol: "Uni_Code_df2",
							summarycollection: summarycollection,
							fromcollection1: this.collectionnames["Table 1 Unmatched"],
							fromcollection2: this.selectedannexure,
							tocollection1: this.collectionnames["Table 1 Matched"],
							tocollection2: this.collectionnames["Table 2 Matched"]

						}
					}
				}
			}
			let success = false;
			this.loading = true;
			this.axios
				.post("/v2/financialclose/analysis/updatetransactionmatching/" + this.metadata._id, ndt)
				.then((ele) => {
					if (ele.data.status === "success") {
						this.comparedialog = false;

						this.$store.commit("sbSuccess", "Matched Successfully");
						success = true;
					} else throw new Error("Error updating");
				})

				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.compareloading = false;
					this.loading = false;
					if (success) this.readAnnexure(this.selectedannexure, this.verificationlist[0]?._id);
				});
		},
		moveTransactionNew(d, type) {
			let ndtnew = {}
			this.compareloadingNew = true;
			let summarycollection = this.collectionnames["Summary"];
			let allanexures = {
				"a": this.collectionnames["Table 1 Matched"],
				"b": this.collectionnames["Table 2 Matched"],
				"c": this.collectionnames["Table 1 Unmatched"],
				"d": this.collectionnames["Table 2 Unmatched"],
			}
			if (this.annexurenames[this.selectedannexure] === 'Table 1 Matched') {
				if (type === "approve") {
				ndtnew = {
					filter: {
						id: d._id,
						type: type,
						allanexures: allanexures,
						countercodes: this.selectedidsNew,
						mycol: "Uni_Code_df1",
						countercol: "Uni_Code_df2",
						summarycollection: summarycollection,
						fromcollection1: this.collectionnames["Table 1 Matched"],
						tocollection1: this.collectionnames["Table 1 Unmatched"],
						fromcollection2: this.collectionnames["Table 2 Matched"],
						tocollection2: this.collectionnames["Table 2 Unmatched"]
					}
				}
				} else if (type === "reject") {
					ndtnew = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							mycol: "Uni_Code_df1",
							countercol: "Uni_Code_df2",
							summarycollection: summarycollection,
							fromcollection1: this.selectedannexure,
							tocollection1: this.collectionnames["Table 1 Unmatched"],
							fromcollection2: this.collectionnames["Table 2 Matched"],
							tocollection2: this.collectionnames["Table 2 Unmatched"]
						}
					}
				}
				// console.log(ndtnew);

			} else if (this.annexurenames[this.selectedannexure] === 'Table 2 Matched') {
				if (type === "approve") {
				ndtnew = {
					filter: {
						id: d._id,
						type: type,
						allanexures: allanexures,
						countercodes: this.selectedidsNew,
						mycol: "Uni_Code_df2",
						countercol: "Uni_Code_df1",
						summarycollection: summarycollection,
						fromcollection1: this.collectionnames["Table 2 Matched"],
						tocollection1: this.collectionnames["Table 1 Unmatched"],
						fromcollection2: this.collectionnames["Table 1 Matched"],
						tocollection2: this.collectionnames["Table 2 Unmatched"]
					}
				}
				} else if (type === "reject") {
					ndtnew = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							mycol: "Uni_Code_df1",
							countercol: "Uni_Code_df2",
							summarycollection: summarycollection,
							fromcollection1: this.selectedannexure,
							tocollection1: this.collectionnames["Table 1 Unmatched"],
							fromcollection2: this.collectionnames["Table 2 Matched"],
							tocollection2: this.collectionnames["Table 2 Unmatched"]
						}
					}
				}
			} else if (this.annexurenames[this.selectedannexure] === 'Table 1 Unmatched') {
				if (type === "match") {
					ndtnew = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							countercodes: this.selectedidsNew,
							mycol: "Uni_Code_df1",
							countercol: "Uni_Code_df2",
							summarycollection: summarycollection,
							fromcollection1: this.selectedannexure,
							tocollection1: this.collectionnames["Table 1 Matched"],
							fromcollection2: this.collectionnames["Table 2 Unmatched"],
							tocollection2: this.collectionnames["Table 2 Matched"]
						}
					}
				}
			} else if (this.annexurenames[this.selectedannexure] === 'Table 2 Unmatched') {
				if (type === "match") {
					ndtnew = {
						filter: {
							id: d._id,
							type: type,
							allanexures: allanexures,
							countercodes: this.selectedidsNew,
							mycol: "Uni_Code_df2",
							countercol: "Uni_Code_df1",
							summarycollection: summarycollection,
							fromcollection1: this.selectedannexure,
							tocollection1: this.collectionnames["Table 2 Matched"],
							fromcollection2: this.collectionnames["Table 1 Unmatched"],
							tocollection2: this.collectionnames["Table 1 Matched"]
						}
					}
				}
			}
			//console.log(ndtnew, type);

			let success = false;
			this.loading = true;
			this.axios
				.post("/v2/financialclose/analysis/updatetransactionmatching/" + this.metadata._id, ndtnew)
				.then((ele) => {
					if (ele.data.status === "success") {
						this.comparedialogNew = false;
						this.$store.commit("sbSuccess", "Updated");
						success = true;
					} else throw new Error("Error updating");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.compareloadingNew = false;
					this.loading = false;
					if (success) this.generateAnnexurelistCompo(this.collectionNameList, 1)
				});
		},

		downloadResult() {
			this.loading = true;
			this.axios.post("/v2/bot/analysis/downloadfile/" + this.botdetails._id).then(dt => {
				if (dt.data.status === "success") {
					let data = dt.data.data[0] || {};
					if (data.stage === 0 || data.stage === 4) this.$store.commit("sbSuccess", "File is being generated please try after sometime");
					else if (data.stage === 2) this.$store.commit("sbSuccess", "There was an error while generating file");
					else if (data.upload) this.$nova.downloadFile(data.upload);
					else return "no file found to download"
				} else {
					throw new Error(dt.data.message || "Error re-opening project");
				}
			}).catch(err => {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
				console.log(err);
			}).finally(() => {
				this.loading = false;
			})
		},
		openRecoincilationDialogue() {
			this.recoincilationItem = {}
			this.recoincilationErrorItem = {}
			this.recoincilationDialogue = true;
		},
		getRecoincialtionData() {
			this.axios.post('/v2/financialclose/analysis/getTransactionmatchingDetails/' + this.id).then(dt => {
				if (dt.data.status === 'success') {
					this.recoincilationData = dt.data.data
				} else {
					throw new Error(dt.data.message || "Not added")
				}
			}).catch(err => {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
				console.log(err);
			}).finally(() => {
				this.loading = false;
			})
		},
		addRecoincilationItem(collection) {
			this.recoincilationItem.attachmentfile = [];
			this.recoincilationItem.index = this.recoincilationSummaryData.length + 5;
			this.axios.post('v2/financialclose/analysis/updateAnnexure/' + collection, {
				'data': this.recoincilationItem,
				// pid: this.id
			}).then(dt => {
				if (dt.data.status === 'success') {
					this.$store.commit("sbSuccess", dt.data.message || "Added Successfully");
					this.recoincilationDialogue = false;
					this.recoinSummary();
					// this.getRecoincialtionData()
				} else {
					this.recoincilationErrorItem = dt.data.data[0].index0
					throw new Error(dt.data.message || "Not added")
				}
			}).catch(err => {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
				console.log(err);
			}).finally(() => {
				this.loading = false;
			})
		},
		deleteRecoincilationItem(item) {
			this.axios.post('/v2/financialclose/analysis/deletetransactionmatching/' + item._id).then(dt => {
				if (dt.data.status === 'success') {
					this.$store.commit("sbSuccess", dt.data.message || "Deleted Successfully");
					this.getRecoincialtionData();
				} else {
					throw new Error(dt.data.message || "Not added")
				}
			}).catch(err => {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
				console.log(err);
			}).finally(() => {
				this.loading = false;
			})
		},
		addfilterbox() {
			this.addfilterdialog = true;
			this.filterobject = {
				tableid: [],
				column: [],
				operator: [],
				description: undefined,
				excludetype: 'Exclude',
				tag: '',
				status: '',
				filterword: '',
			}
			this.filterobjecterror = {};
			this.isEditing = false;
		},
		editfilterbox(item) {
			this.addfilterdialog = true;
			this.filterobject = {
				...item
			};
		},
		validateFilterObject() {
			this.filterobjecterror = {};
			if (!this.filterobject.description) {
				this.$set(this.filterobjecterror, 'description', "Description cannot be blank.");
			}
			if (!this.filterobject.tag) {
				this.$set(this.filterobjecterror, 'tag', "Tag cannot be blank.");
			}
			if (!this.filterobject.filterword) {
				this.$set(this.filterobjecterror, 'filterword', "Filter text cannot be blank.");
			}
			if (this.filterobject.column === null || this.filterobject.column.length === 0) {
				this.$set(this.filterobjecterror, 'column', "Column cannot be blank.");
			}
			if (this.filterobject.operator === null || this.filterobject.operator.length === 0) {
				this.$set(this.filterobjecterror, 'operator', "Operator cannot be blank.");
			}
			if (this.filterobject.tableid === null || this.filterobject.tableid.length === 0) {
				this.$set(this.filterobjecterror, 'tableid', "Table cannot be blank.");
			}
			if (this.filterobject.excludetype === undefined) {
				this.$set(this.filterobjecterror, 'excludetype', "Exclude type cannot be blank.");
			}

			return Object.keys(this.filterobjecterror).length === 0;
		},
		addfilterPush() {
			if (this.validateFilterObject()) {
				this.filterList.push({
					...this.filterobject
				});
				this.resetFilter();
				this.addfilterdialog = false;
				this.formateData();
			}
		},
		editfilterboxPush(item) {
			this.filterobjecterror = {};
			this.addfilterdialog = true;
			this.filterobject = {
				...item
			};
			this.isEditing = true;
			this.currentIndex = item.indexno;
		},
		deletefilterboxPush(item) {
			this.filterList.splice(item.indexno, 1);
			this.formateData();
		},
		updatefilterPush() {
			if (this.validateFilterObject()) {
				this.$set(this.filterList, this.currentIndex, {
					...this.filterobject
				});
				this.formateData();
				this.addfilterdialog = false;
				this.resetFilter();
			}
		},
		resetFilter() {
			this.filterobject.tag = '',
				this.filterobject.status = '',
				this.filterobject.tableid = '',
				this.filterobject.operator = '',
				this.filterobject.filterword = '',
				this.filterobject.excludetype = '',
				this.filterobject.description = '',
				this.filterobject.column = ''
			this.isEditing = false;
			this.currentIndex = null;
		},
		addfilterConfimation(){

			this.checkChangesFilter();
		},
		addrulesConfimation(){
			this.checkChangesRules();
		},
		
		addfilter(type) {
			this.addfilterconfimloading = true;
			this.filterobject.bpid = this.alaysisDetails.bpid;
			this.filterobject.pid = this.alaysisDetails.pid;
			let data = {
				filters: this.filterList
			};
			this.axios.post("/v2/financialclose/analysis/updateTmFilterById/" + this.alaysisDetails.pid + '/' + this.alaysisDetails._id + '/' + this.alaysisDetails.bpid, {
					data: data
				})
				.then((ele) => {
					if (ele.data.status === "success") {
						this.filterobject = {};
						this.$store.commit("sbSuccess", "Filter added successfully");
						this.addfilterconfim = false;
						// this.e1 = 3

						this.getfilterlist();
						if (type === 'continue') { 
							this.e1 = 3 
							}
					} else {
						if (ele.data.data.length > 0 && ele.data.data[0].index0)
							this.filterobjecterror = ele.data.data[0].index0;
						else
							throw new Error(ele.data.message);
					}
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.addfilterconfimloading = false;
				});
		},
		updatefilter(id) {
			this.addfilterdialogloading = true;
			this.axios.post("/v2/financialclose/analysis/updateTmFilterById/" + id, {
					data: this.filterobject,
				})
				.then((dt) => {
					if (dt.data.status === "success") {
						this.$store.commit("sbSuccess", "Filter Successfully Updated");
						this.addfilterdialog = false;
						this.filterobject = {};
						this.refreshData();
					} else {
						if (dt.data.data.length > 0 && dt.data.data[0].index0)
							this.filterobjecterror = dt.data.data[0].index0;
						else
							throw new Error(dt.data.message);
					}
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.addfilterdialogloading = false;
				});
		},
		enablefilterbox(id, action) {
			this.loading = true;
			const url = `/v2/financialclose/analysis/${action === "Enable" ? "enable" : "disable"}TmFilterById/${id}`;
			this.axios.post(url, {})
				.then(({
					data
				}) => {
					if (data.status === "success") {
						this.refreshData();
						this.$store.commit("sbSuccess", `${action.toLowerCase()} successfully`);
					} else {
						throw new Error(data.message || "Error performing action");
					}
				})
				.catch((error) => {
					this.$store.commit("sbError", error.message || error || "Unknown error!");
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		enablemiddlewarebox(id, action) {
			this.loading = true;
			const url = `/v2/financialclose/analysis/${action === "Enable" ? "enable" : "disable"}TmMiddlewareById/${id}`;
			this.axios.post(url, {})
				.then(({
					data
				}) => {
					if (data.status === "success") {
						this.getmiddlewarelist();
						this.$store.commit("sbSuccess", `Mapping master successfully ${action.toLowerCase()}d`);
					} else {
						throw new Error(data.message || "Error performing action");
					}
				})
				.catch((error) => {
					this.$store.commit("sbError", error.message || error || "Unknown error!");
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		changeMid(id, it) {
			let type = '';
			if (it) {
				type = 'Enable';
			} else {
				type = 'Disable';
			}
			this.enablemiddlewarebox(id, type);
		},
		addMiddlewarebox() {
			this.addMiddlewaredialog = true;
			this.middlewareObject = {};
			this.middlewareObjecterror = {};
			this.middFile = null;
		},
		middlewareValition(){
			this.middlewareObjecterror = {};
			if (!this.middlewareObject.middlewarename) {
				this.$set(this.middlewareObjecterror, 'name', "Name text cannot be blank.");
			}
			if (this.middlewareObject.basefile === undefined  || this.middlewareObject.basefile === null || this.middlewareObject.basefile.length === 0) {
				this.$set(this.middlewareObjecterror, 'basefile', "Basefile cannot be blank.");
			}
			if (this.middFile === null) {
				this.$set(this.middlewareObjecterror, 'middFile', "File is required");
				return
			}
			return Object.keys(this.middlewareObjecterror).length === 0;
		},
		async addMiddleware() {

			if (this.middlewareValition()) {
				
			
			if (this.middFile === null) {
				this.$store.commit("sbError", "Middleware File required");
				return
			}
			this.addMiddlewaredialogloading = true;
			this.middlewareObject.bpid = this.alaysisDetails.bpid;
			this.middlewareObject.pid = this.alaysisDetails.pid;
			this.middlewareObject.tmaid = this.alaysisDetails._id;
			this.middlewareObject.bid = this.alaysisDetails.bid;


			const uploadResponse = await this.$nova.uploadFile(this.axios, this.middFile, {});
			if (uploadResponse.data.status === "success") {
				const middlewarfile = uploadResponse.data.data[0]._id;
				this.middlewareObject.middlewarefile = [middlewarfile];
				this.middlewareObject.middlewarefilepath = uploadResponse.data.data[0].path;
				let data = this.middlewareObject;
				this.axios.post("/v2/financialclose/analysis/addTmMiddleware", {
						data: data
					})
					.then((ele) => {
						if (ele.data.status === "success") {
							this.addMiddlewaredialog = false;
							this.$store.commit("sbSuccess", "Mapping master successfully saved");
							this.getmiddlewarelist();
						} else {
							if (ele.data.data.length > 0 && ele.data.data[0].index0){
								this.middlewareObjecterror = ele.data.data[0].index0;
							}
							else
								throw new Error(ele.data.message);
						}
					})
					.catch((err) => {
						this.$store.commit("sbError", err.message || err || "Unknown error!");
						console.log(err);
					})
					.finally(() => {
						this.addMiddlewaredialogloading = false;
					});
				}
			}
		},
		async updateMiddleware(id) {
			if (this.middlewareValition()) {
			const uploadResponse = await this.$nova.uploadFile(this.axios, this.middFile, {});
			if (uploadResponse.data.status === "success") {
				const middlewarfile = uploadResponse.data.data[0]._id;
				this.middlewareObject.middlewarefilepath = uploadResponse.data.data[0].path;
				this.middlewareObject.middlewarefile = [middlewarfile];
				let data = this.middlewareObject;
				this.axios.post("/v2/financialclose/analysis/upadteTmMiddleware/" + id, {
						data: data
					})
					.then((ele) => {
						if (ele.data.status === "success") {
							this.addMiddlewaredialog = false;
							this.$store.commit("sbSuccess", "Mapping master successfully saved");
							this.getmiddlewarelist();
						} else {
							if (ele.data.data.length > 0 && ele.data.data[0].index0)
								this.middlewareObjecterror = ele.data.data[0].index0;
							else
								throw new Error(ele.data.message);
						}
					})
					.catch((err) => {
						this.$store.commit("sbError", err.message || err || "Unknown error!");
						console.log(err);
					})
					.finally(() => {
						this.addMiddlewaredialog = false;
					});
			}
			}

		},
		editmiddlewarebox(item) {
			this.addMiddlewaredialog = true;
			this.middlewareObject = { ...item };
			this.middlewareObject.basefile = item.basefile || '';
			this.middFile = null;
			this.middlewareObjecterror = {};
		},
		hasValidData(data) {
			return data && Array.isArray(data) && data.length > 0 && data.some(item => item !== null);
		},
		storeTotal(len) {
			this.total = len;
			this.pagecount = Math.ceil(this.total / this.perpage);
		},
		storeTotalnew(len) {
			this.totalnew = len;
			this.pagecountnew = Math.ceil(this.totalnew / this.perpagenew);
		},
		storeTotalfifo(len) {
			this.totalfifo = len;
			this.pagecountfifo = Math.ceil(this.totalfifo / this.perpagefifo);
		},
		pageItemsfifo() {
			this.storeTotalfifo((this.fifoannexData || []).length)
			return this.fifoannexData.slice((this.pagefifo - 1) * this.perpagefifo, (this.pagefifo) * this.perpagefifo);
		},
		pageItemsNew() {
			this.storeTotalnew((this.newAnnexersdata || []).length)
			return this.newAnnexersdata.slice((this.pagenew - 1) * this.perpagenew, (this.pagenew) * this.perpagenew);
		},
		pageItems() {
			this.storeTotal((this.currentannexuredata || []).length)
			return this.currentannexuredata.slice((this.page - 1) * this.perpage, (this.page) * this.perpage);
		},
		setupAI(){
			this.setupAIdilog = true;
			this.setupaiObj = {};
			this.initializeSetupAiObj();
			// this.setupAIdilogloading = true;
		},
		resetAI(){
			this.copycolumns = Object.values(this.columns);
		},
		MatchedAIitem(item){
			let mathedIds = item.map((item) => item._id)
			let summarycollection = this.collectionnames["Summary"];
			let ai_collection = this.collectionnames["AI_Matches"];
			let allanexures = {
				"a": this.collectionnames["Table 1 Matched"],
				"b": this.collectionnames["Table 2 Matched"],
				"c": this.collectionnames["Table 1 Unmatched"],
				"d": this.collectionnames["Table 2 Unmatched"],
			};
			let filter = {
			'ids': mathedIds,
			'allanexures': allanexures,
			'summarycollection': summarycollection,
			'aicollection':ai_collection,
			};
			let success = false
			this.loading = true;
			this.axios
				.post("/v2/financialclose/analysis/updateaitransactionmatching", {
					filter
				})
				.then((ele) => {
					if (ele.data.status === "success") {
						success = true
						this.$store.commit("sbSuccess", "Matched Items Added Successfully ");
					} else {
						throw new Error("Error Matched Items")
					}
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					if (success) {
						this.generateAnnexurelistAI(this.selectedannexure,this.verificationlist[0]?._id)
					}
					this.loading = false;
				});
		},
		startRunAI(item){
			this.setupAIdilogloading = true;
			let data = {
				isAI: true,
				isittm: true,
				tmaid: this.alaysisDetails._id,
				toprocess: this.alaysisDetails.to_process,
				ai_setup:item,
			}
			this.axios
				.post("/v2/bot/analysis/startrun/" + this.botdetails._id, {
					data
				})
				.then((ele) => {
					if (ele.data.status === "success") {
						this.$store.commit("sbSuccess", "Run AI Started");
						this.setupAIdilogloading = false;
						this.executionbtnAI = true;
					} else throw new Error("Error starting AI run");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.setupAIdilog = false;
					this.setupAIdilogloading = false;
				});
		},
		startRunAIExecution(count) {
			this.ailoadingimage = true;
			let selectedverification = this.verificationlist[0] || {};
			let rundata = this.rundetails[selectedverification._id] || {};
			this.comboAnnexer = rundata.annexure || [];
			this.collectionNameList = [];
				this.collectionNameList = this.comboAnnexer.filter(item => {
				return item && item.collection ? item.collection.includes("annexure_AIMatched") : false;
				});
			if (this.collectionNameList.length > 0) {
				this.axios.post("/v2/bot/analysis/readannexure/" + this.botdetails._id + "/c/" + this.collectionNameList[0].collection + "/v/" + this.verificationlist[0]._id)
					.then((ele) => {
						if (ele.data.status === "success") {
							this.newAnnexersdata = [];
							this.currentannexuredata = [];
							if (count === 'Two File') {
								this.aiexecutionResult = ele.data.data;
								this.currentannexuredata = ele.data.data;
							} else {
								this.aiexecutionResult = ele.data.data;
								this.newAnnexersdata = ele.data.data;
							}
							
						} else throw new Error("Error starting run");
					})
					.catch((err) => {
						this.$store.commit("sbError", err.message || err || "Unknown error!");
						console.log(err);
					})
					.finally(() => {
						this.ailoadingimage = false;
					});
			} else {
				this.$store.commit("sbError", "AI Result has not yet been run");
				this.ailoadingimage = false;
			}

		},
		toggleSelectAll(){
			if (this.selectallfifo) {
				this.selectFifo = this.pageItemsfifo().map((item, index) => index); 
			} else {
				this.selectFifo = []; 
			}
			
		},
		startRunFifo() {
			this.loading = true;
			let data = {
				isFIFO: true,
				isittm: true,
				tmaid: this.alaysisDetails._id,
				toprocess: this.alaysisDetails.to_process
			}
			this.axios
				.post("/v2/bot/analysis/startrun/" + this.botdetails._id, {
					data
				})
				.then((ele) => {
					if (ele.data.status === "success") {
						this.$store.commit("sbSuccess", "Run FIFO Started");
						this.executionbtn = true;
						this.loadBot();
					} else throw new Error("Error starting fifo run");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		startRunFifoExecution() {
			let selectedverification = this.verificationlist[0] || {};
			let rundata = this.rundetails[selectedverification._id] || {};
			this.comboAnnexer = rundata.annexure || [];
			this.collectionNameList = this.comboAnnexer.filter(item => item.collection.includes("annexure_FIFO"));

			if (this.collectionNameList.length > 0) {
				this.axios.post("/v2/bot/analysis/readannexure/" + this.botdetails._id + "/c/" + this.collectionNameList[0].collection + "/v/" + this.verificationlist[0]._id)
					.then((ele) => {
						if (ele.data.status === "success") {
							this.fifoexecutionResult = ele.data.data;
							this.runfifoppop = true;
							this.executionbtn = false;
						} else throw new Error("Error starting run");
					})
					.catch((err) => {
						this.$store.commit("sbError", err.message || err || "Unknown error!");
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				this.$store.commit("sbError", "FIFO Analysis has not yet been run");
			}

		},
		onDragStart(index) {
			this.draggedItemIndex = index;
		},
		onDropFilter(index) {
			const draggedItem = this.formatedData[this.draggedItemIndex];
			this.formatedData.splice(this.draggedItemIndex, 1);
			this.formatedData.splice(index, 0, draggedItem);
			this.draggedItemIndex = null;
			this.saveNewOrder(this.formatedData, 'filter');
		},
		onDragEnd() {
			this.draggedItemIndex = null;
		},
		saveNewOrder(newOrder, type) {
			if (type === 'filter') {
				this.filterList = newOrder;
			} else {
				this.rulesdata = newOrder;
			}
		},
		onDroprule(index) {
			const draggedItem = this.rulesdata[this.draggedItemIndex];
			this.rulesdata.splice(this.draggedItemIndex, 1);
			this.rulesdata.splice(index, 0, draggedItem);
			this.draggedItemIndex = null;
			this.saveNewOrder(this.rulesdata, 'rules');
		},
		getcomboList(d) {
			if (d === null || d === undefined) {
				return;
			}
			this.loading = true;
			const key = Object.keys(this.newannexerCombodata[d])[0];
			const value = Object.values(this.newannexerCombodata[d])[0];
			this.selectedannexure = value;
			let data = {
				collection: value
			}
			this.loading = true;
			this.axios
				.post("/v2/financialclose/analysis/getdatabycombocode/" + key, {
					data: data
				})
				.then((dt) => {
					if (dt.data.status === "success") {
						this.newpageinationEmpty();
						this.newAnnexersdata = dt.data.data;
						this.originalAnnexersdata = [...this.newAnnexersdata];
					} else throw new Error(dt.data.message || "Error Get Combo Code");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});

		},
		recoinSummary() {
			let selectedverification = this.verificationlist[0] || {};
			let rundata = this.rundetails[selectedverification._id] || {};
			this.comboAnnexer = rundata.annexure || [];
			if (this.comboAnnexer.length > 0) {
				this.collectionNameList = this.comboAnnexer.filter(item => item.name === "Reconcillation Summary");
				this.recoinSummaryResult(this.collectionNameList[0].collection, selectedverification._id);
			} else {
				this.$store.commit("sbError", "Analysis has not yet been run");
			}

		},
		indexOrder(items) {
			return items.sort((a, b) => a.index - b.index);
		},
		removeExcluded() {
      // Remove the item with specified criteria
      this.recoincilationSummaryData = this.recoincilationSummaryData.filter(item => {
        // Only include items that do not match the criteria
        return !(item.Reconcilliation === "Total Excluded" && item.Amount === "{'LESS': [], 'ADD': []}");
      });
    },
		recoinSummaryResult(collection, id) {
			this.recoincilationCollection = collection;
			this.loading = true;
			this.axios.post("/v2/bot/analysis/readannexure/" + this.alaysisDetails.bpid + "/c/" + collection + "/v/" + id)
				.then((dt) => {
					if (dt.data.status === "success") {
					this.recoincilationSummaryData = this.indexOrder(dt.data.data);	
					this.removeExcluded();
					} else throw new Error(dt.data.message || "Error Get Recoincilation Summary");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		newpageinationEmpty() {
			this.pagenew = 1;
			this.pagecountnew = 1;
			this.perpagenew = 50;
			this.totalnew = 0;
		},
		pageinationEmpty() {
			this.page = 1;
			this.pagecount = 1;
			this.perpage = 50;
			this.total = 0;
		},
		createEmptyObject(templateObject) {
			return Object.keys(templateObject).reduce((acc, key) => {
				acc[key] = '';
				return acc;
			}, {});
		},
		parsedAmount(amount) {
			try {
				return JSON.parse(amount.replace(/'/g, '"'));
			} catch (e) {
				console.error('Error parsing Amount:', e);
				return {};
			}
		},
		globalfilterpopNew(value){
			if (value) {
			this.oneormorefile = true;
			const key = Object.keys(this.newannexerCombodata[this.selectedCombo])[0];	
			const values = Object.values(this.newannexerCombodata[this.selectedCombo])[0];	
			const data = this.newAnnexersdata[0][value];
			this.selectFilter = value;
			this.globalfilterObject = {};
			this.globalfilterObjecterror = {};
			this.selectcollection = values;
			this.selectkey = key;
			if (typeof data === 'number') {
					this.typeOffilter = "Number";
					this.selectfilterdialog = true;
				} else if (typeof data === 'string') {
					if (this.isValidDatetrue(data)) {
						this.typeOffilter = "Date";
						this.selectfilterdialog = true;
					} else {
						this.typeOffilter = "Text";
						this.selectfilterdialog = true;
					}
				}			
			} else {
				console.log(value, "ELSE");
			}
			
		},
		globalfilterpopMatch(value,collection,recedata,match,matchtype){
			this.mathcFilter = match;
			this.matchTypeFilter = matchtype;
			if (value) {
				this.selectFilter = value;
				this.globalfilterObject = {};
				this.globalfilterObjecterror = {};
				this.selectcollection = collection;
				const data = recedata[0][value];
				if (typeof data === 'number') {
					this.typeOffilter = "Number";
					this.selectfilterdialog = true;
				} else if (typeof data === 'string') {
					if (this.isValidDatetrue(data)) {
						this.typeOffilter = "Date";
						this.selectfilterdialog = true;
					} else {
						this.typeOffilter = "Text";
						this.selectfilterdialog = true;
					}
				}
			} else {
				console.log(value, "ELSE");
			}

			
		},
		// globalfilterpopMatch(value,collection){
		// 	console.log(collection,"collectioncollectioncollectioncollection");
			
		// 	if (value) {
		// 		this.selectFilter = value;
		// 		this.globalfilterObject = {};
		// 		this.globalfilterObjecterror = {};
		// 		this.selectcollection = collection;
		// 		const data = this.currentannexuredata[0][value];
		// 		if (typeof data === 'number') {
		// 			this.typeOffilter = "Number";
		// 			this.selectfilterdialog = true;
		// 		} else if (typeof data === 'string') {
		// 			if (this.isValidDatetrue(data)) {
		// 				this.typeOffilter = "Date";
		// 				this.selectfilterdialog = true;
		// 			} else {
		// 				this.typeOffilter = "Text";
		// 				this.selectfilterdialog = true;
		// 			}
		// 		}
		// 	} else {
		// 		console.log(value, "ELSE");
		// 	}
			
		// },
		globalfilterpop(value, collection) {
			if (value) {
				this.selectFilter = value;
				this.globalfilterObject = {};
				this.globalfilterObjecterror = {};
				this.selectcollection = collection;
				const data = this.currentannexuredata[0][value];
				if (typeof data === 'number') {
					this.typeOffilter = "Number";
					this.selectfilterdialog = true;
				} else if (typeof data === 'string') {
					if (this.isValidDatetrue(data)) {
						this.typeOffilter = "Date";
						this.selectfilterdialog = true;
					} else {
						this.typeOffilter = "Text";
						this.selectfilterdialog = true;
					}
				}
			} else {
				console.log(value, "ELSE");
			}
		},
		createFilterObject(ope, range, range1, range2, col, type) {
			if (type === 'Number') {
				return {
					operation: ope,
					value: range,
					value1: range1,
					value2: range2,
					column: col,
					type: type
				};
			} else if (type === 'Date') {
				return {
					operation: ope,
					value: range,
					value1: this.convertToDateTimeString(range1),
					value2: this.convertToDateTimeString(range2),
					column: col,
					type: type
				};
			} else {
				return {
					operation: ope,
					value1: range,
					column: col,
					type: type
				};
			}

		},
		validateFilter(operation, range, typeOffilter, range1, range2) {
			var isValid = true;
			this.globalfilterObjecterror = {
				operation: '',
				range: '',
				range1: '',
				range2: ''
			};
			if (typeOffilter === 'Text') {
				if (!operation || operation.length === 0 || operation == null) {
					this.globalfilterObjecterror.operation = 'Operation is required';
					isValid = false;
				}
				if (!range || range == null) {
					this.globalfilterObjecterror.range = 'Range is required';
					isValid = false;
				}
			}
			if (typeOffilter === 'Number') {

				if (!operation || operation.length === 0 || operation == null) {
					this.globalfilterObjecterror.operation = 'Operation is required';
					isValid = false;
				}
				if (!(typeof range1 === 'number')) {
					this.globalfilterObjecterror.range1 = 'Range 1 is required';
					isValid = false;
				}
				if (this.globalfilterObject.operation === 'Between' && !(typeof range1 === 'number')) {
					this.globalfilterObjecterror.range2 = 'Range 2 is required for "Between" operation';
					isValid = false;
				}
				if (this.globalfilterObject.operation === 'Between' && (!(range1 < range2))) {
					this.globalfilterObjecterror.range2 = 'Range 1 is Greater than Range 2';
					isValid = false;
				}

			}
			if (typeOffilter === 'Date') {
				if (!operation || operation.length === 0 || operation == null) {
					this.globalfilterObjecterror.operation = 'Operation is required';
					isValid = false;
				}


				if (!range1) {
					this.globalfilterObjecterror.range1 = 'Range 1 is required';
					isValid = false;
				}
				if (this.globalfilterObject.operation === 'Between' && (!range2)) {
					this.globalfilterObjecterror.range2 = 'Range 2 is required for "Between" operation';
					isValid = false;
				}
			}
			return isValid;
		},
		async runfilter(operation, range, range1, range2, selectFilter, typeOffilter, selectcollection, selectkey,mathcfilter,matchtypefilter) {
			if (!this.validateFilter(operation, range, typeOffilter, range1, range2)) {
				return;
			}
			const filterObj = this.createFilterObject(operation, range, range1, range2, selectFilter, typeOffilter);
			this.globalArray.push(filterObj);
			
			if ((mathcfilter === 'Manual') && (matchtypefilter === 'twofile' || 'single')) {
			const query = selectkey ? { "combo-code": selectkey } : null;
			const filter = { data: this.globalArray };
			const Apipath = `/v2/bot/analysis/readannexurewithfilter/${selectcollection}`;

			try {
				const response = await this.axios.post(Apipath, query ? { filter, query } : { filter });
				if (response.data.status === 'success') {
				this.filterArray.push(filterObj);
				if (this.matchTypeFilter === 'twofile') {
					this.formateddataforfilter = response.data.data;
				} else {
					this.formateddataforfilterNew = response.data.data;
				}
				this.globalfilterObjecterror = {};
				} else {
				throw new Error(response.data.message || 'Error fetching filter properties');
				}
			} catch (err) {
				this.$store.commit('sbError', err.message || 'Unknown error');
				console.error(err);
			} finally {
				this.selectfilterdialogloading = false;
				this.globalfilterObject = {};
				this.globalfilterObjecterror = {};
				
			}
					
			} else {
			const query = selectkey ? { "combo-code": selectkey } : null;
			const filter = { data: this.globalArray };
			const Apipath = `/v2/bot/analysis/readannexurewithfilter/${selectcollection}`;

			try {
				const response = await this.axios.post(Apipath, query ? { filter, query } : { filter });
				if (response.data.status === 'success') {
				this.filterArray.push(filterObj);
				if (selectkey) {
					this.newAnnexersdata = response.data.data;
				} else {
					this.currentannexuredata = response.data.data;
				}
				this.globalfilterObjecterror = {};
				} else {
				throw new Error(response.data.message || 'Error fetching filter properties');
				}
			} catch (err) {
				this.$store.commit('sbError', err.message || 'Unknown error');
				console.error(err);
			} finally {
				this.selectfilterdialogloading = false;
				this.globalfilterObject = {};
				this.globalfilterObjecterror = {};
				
			}
			}
			},
		globalfilterpopClose() {
			this.globalfilterObject = {};
			this.globalfilterObjecterror = {};
			this.filterArray = [];
			this.globalArray = [];
			this.selectfilterdialog = false;
		},
		async reomveGobalefilter(collection,selectkey,mathcfilter,matchtypefilter) {
		if ((mathcfilter === 'Manual') && (matchtypefilter === 'twofile' || 'single')) {
			const query = selectkey ? { "combo-code": selectkey } : null;
		const filter = { data: [] };
		const Apipath = `/v2/bot/analysis/readannexurewithfilter/${collection}`;

		try {
			const response = await this.axios.post(Apipath, query ? { filter, query } : { filter });
			if (response.data.status === 'success') {
			this.filterArray = [];
			this.globalArray = [];
			this.globalfilterObjecterror = {};
			this.selectfilterdialog = false;
			if (this.matchTypeFilter === matchtypefilter) {
				this.formateddataforfilter = response.data.data;
			} else {
				this.formateddataforfilterNew = response.data.data;
			}
			} else {
			this.newAnnexersdata = [];
			this.currentannexuredata = [];
			throw new Error(response.data.message || 'Error fetching filter properties');
			}
		} catch (err) {
			this.$store.commit('sbError', err.message || 'Unknown error');
			console.error(err);
		} finally {
			this.selectfilterdialogloading = false;
			this.selectfilterdialog = false;
		}
		} else {
			const query = selectkey ? { "combo-code": selectkey } : null;
		const filter = { data: [] };
		const Apipath = `/v2/bot/analysis/readannexurewithfilter/${collection}`;

		try {
			const response = await this.axios.post(Apipath, query ? { filter, query } : { filter });
			if (response.data.status === 'success') {
			this.filterArray = [];
			this.globalArray = [];
			this.globalfilterObjecterror = {};
			this.selectfilterdialog = false;
			if (selectkey) {
				this.newAnnexersdata = response.data.data;
			} else {
				this.currentannexuredata = response.data.data;
			}
			} else {
			this.newAnnexersdata = [];
			this.currentannexuredata = [];
			throw new Error(response.data.message || 'Error fetching filter properties');
			}
		} catch (err) {
			this.$store.commit('sbError', err.message || 'Unknown error');
			console.error(err);
		} finally {
			this.selectfilterdialogloading = false;
			this.selectfilterdialog = false;
		}
		}
		
		},
		async deleteGobalefilter(filarr, index, collection, selectkey,mathcfilter,matchtypefilter) {

			if (this.mathcFilter === mathcfilter) {
			this.selectedannexure = collection;
			filarr.splice(index, 1);
			const query = selectkey ? { "combo-code": selectkey } : null;
			const filterData = filarr.length > 0 ? filarr : [];	
			const filter = {
				data: filterData,
			};
			const Apipath = `/v2/bot/analysis/readannexurewithfilter/${collection}`;
			this.selectfilterdialogloading = true;
			try {
			const response = await this.axios.post(Apipath, query ? { filter, query } : { filter });
			if (response.data.status === 'success') {
				this.filterArray = [...filterData];
				this.globalArray = [...filterData];
			if (this.matchTypeFilter === matchtypefilter) {
				this.formateddataforfilter = response.data.data;
				} else {
				this.formateddataforfilterNew = response.data.data;
				}
				this.selectfilterdialogloading = false;
			} else {
			this.newAnnexersdata = [];
			this.currentannexuredata = [];
			throw new Error(response.data.message || 'Error fetching filter properties');
			}
		} catch (err) {
			this.$store.commit('sbError', err.message || 'Unknown error');
			console.error(err);
		} finally {
			this.selectfilterdialogloading = false;
		}
			} else {
			this.selectedannexure = collection;
			filarr.splice(index, 1);
			const query = selectkey ? { "combo-code": selectkey } : null;
			const filterData = filarr.length > 0 ? filarr : [];	
			const filter = {
				data: filterData,
			};
			const Apipath = `/v2/bot/analysis/readannexurewithfilter/${collection}`;
			this.selectfilterdialogloading = true;
			try {
			const response = await this.axios.post(Apipath, query ? { filter, query } : { filter });
			if (response.data.status === 'success') {
				this.filterArray = [...filterData];
				this.globalArray = [...filterData];
				if (selectkey) {
				this.newAnnexersdata = response.data.data;
				} else {
				this.currentannexuredata = response.data.data;
				}
				this.selectfilterdialogloading = false;
			} else {
			this.newAnnexersdata = [];
			this.currentannexuredata = [];
			throw new Error(response.data.message || 'Error fetching filter properties');
			}
		} catch (err) {
			this.$store.commit('sbError', err.message || 'Unknown error');
			console.error(err);
		} finally {
			this.selectfilterdialogloading = false;
		}
			}
		
		},
	isValidDate(dateString) {
    const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

    if (isoDateTimeRegex.test(dateString)) {
        let date = new Date(dateString);
        if (!isNaN(date.getTime())) {
            return this.$nova.formatDate(date); 
        }
    }
    return dateString;
},
isValidDatetrue(dateString) {
    const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

    if (isoDateTimeRegex.test(dateString)) {
        let date = new Date(dateString);
        if (!isNaN(date.getTime())) {
            return true; 
        }
    }
    return false;
},
convertToDateTimeString(dateString) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        return `${dateString}T00:00:00`;
    }
    return dateString;
},
deleteAIsetup(name,index){
 if (this.setupaiObj[name]) {
        this.$delete(this.setupaiObj, name); 
      }
      if (this.copycolumns[index]) {
        this.$delete(this.copycolumns, index); 
      }
},

	},
	computed: {
		columnsArray() {
      // Convert columns object to an array for v-for usage
      return Object.values(this.columns);
    },
		compareObjects() {
			if (this.filterArray.length > 0) {
				return this.filterArray.some((obj) => obj.column === this.selectFilter);
			} else {
				return false
			}

		},
		filterItem() {
			return Object.keys(this.pageItems()[0] || []);
		},
		annexercomboKeys() {
			return this.newannexerCombodata.map(item => Object.keys(item)[0]);
		},
		sortedData() {
			const data = this.fifoexecutionResult.slice(1);
			return data.sort((a, b) => {
				const aValue = a[this.sortColumn];
				const bValue = b[this.sortColumn];

				if (typeof aValue === 'undefined' || aValue === null) return -1;
				if (typeof bValue === 'undefined' || bValue === null) return 1;

				// Convert values to numbers if possible
				const numA = parseFloat(aValue);
				const numB = parseFloat(bValue);

				if (!isNaN(numA) && !isNaN(numB)) {
					// Both values are numbers, sort numerically
					return this.sortDesc ? numB - numA : numA - numB;
				} else {
					// Use localeCompare for strings or mixed types
					return this.sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
				}
			});
		},
	},
	watch: {
		"stagefilter"(d) {

			if (d.length === 0) {
				this.currentannexuredata = [...this.originalcurrentannexuredata];
				return;
			}
			const ndt = this.originalcurrentannexuredata.filter(item =>
				(d.includes(1) && item["Match Nature"] === "Automated") ||
				(d.includes(0) && item["Match Nature"] === "Suggested")
			);
			this.currentannexuredata = ndt.length > 0 ? ndt : [this.createEmptyObject(this.originalcurrentannexuredata[0])];

			// if (d.length === 0) {
			// 	this.currentannexuredata = this.originalcurrentannexuredata;
			// 	return;
			// }

			// const isTableMatched = ['Table 1 Matched', 'Table 2 Matched'].includes(this.annexurenames[this.selectedannexure]);
			// if (!isTableMatched) return;

			// const ndt = this.annexuredata[this.selectedannexure].filter(item =>
			// 	(d.includes(1) && item["Match Nature"] === "Automated") ||
			// 	(d.includes(0) && item["Match Nature"] === "Suggested")
			// );

			// this.currentannexuredata = ndt.length > 0 ? ndt : [this.createEmptyObject(this.annexuredata[this.selectedannexure][0])];
		},
		"stagefilterNew"(d) {
			if (d.length === 0) {
				this.newAnnexersdata = [...this.originalAnnexersdata];
				return;
			}

			const ndt = this.originalAnnexersdata.filter(item =>
				(d.includes(1) && item["Match Nature"] === "Automated") ||
				(d.includes(0) && item["Match Nature"] === "Suggested")
			);

			this.newAnnexersdata = ndt.length > 0 ? ndt : [this.createEmptyObject(this.originalAnnexersdata[0])];
		},
		"filterobject.tableid": function(d) {
			this.filtercolums = [];
			if (this.fileConfigInfo[d]) {
				for (let index = 0; index < this.fileConfigInfo[d].columnmap.length; index++) {
					this.filtercolums.push(this.fileConfigInfo[d].columnmap[index][1]);
				}
			}

		},
		"currentannexuredata": function(d) {
			this.storeTotal((d || []).length)
		},
		"newAnnexersdata": function(d) {
			this.storeTotalnew((d || []).length)
		},

		"selectedCombo": function(d) {
			if (d === null || d === undefined) {
				this.loading = false;
				return;
			}
			this.loading = true;
			const key = Object.keys(this.newannexerCombodata[d])[0];
			const value = Object.values(this.newannexerCombodata[d])[0];
			this.selectedannexure = value;
			let data = {
				collection: value
			}
			this.loading = true;
			this.axios
				.post("/v2/financialclose/analysis/getdatabycombocode/" + key, {
					data: data
				})
				.then((dt) => {
					if (dt.data.status === "success") {
						this.newpageinationEmpty();
						this.newAnnexersdata = dt.data.data;
					} else throw new Error(dt.data.message || "Error Get Combo Code");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});

		},
		"selectedCombotwo": function(d) {
			if (d === null || d === undefined) {
				return;
			}
			//this.collectionNameList
			// this.loading = true;
			// const key = Object.keys(this.newannexerCombodata[d])[0];
			// const value = Object.values(this.newannexerCombodata[d])[0];
			// let data = {
			// 	collection: value
			// }
			// console.log(this.newannexerCombodata[d]);
			// console.log(key, value, data);
			// this.loading = true;
			// this.axios
			// 	.post("/v2/financialclose/analysis/getdatabycombocode/" + key, {
			// 		data: data
			// 	})
			// 	.then((dt) => {
			// 		if (dt.data.status === "success") {
			// 			this.newpageinationEmpty();
			// 			this.newAnnexersdata = dt.data.data;
			// 			console.log(this.newAnnexersdata, "newAnnexersdata");
			// 		} else throw new Error(dt.data.message || "Error Get Combo Code");
			// 	})
			// 	.catch((err) => {
			// 		this.$store.commit("sbError", err.message || err || "Unknown error!");
			// 		console.log(err);
			// 	})
			// 	.finally(() => {
			// 		this.loading = false;
			// 	});

		},
		"filterCombo": function(d) {
			if (d === null || d === undefined) {
				return;
			}
			let index = this.newannexerCombodata.findIndex(item => Object.keys(item)[0] === d);
			this.selectedCombo = index;
		},
		"searchvalueNew": function(d) {
			this.filterMatchItemsNewfilter(d);
		},
		"searchvalue": function(d) {
			this.filterMatchItemsNewfilterTwo(d);
		}
	}

};
</script>

<style scoped>
.cursor-move{
    cursor: move;
  }
  .dialog-right {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 400px; /* Adjust width as needed */
  max-width: 100%;
  overflow-y: auto; /* Handles overflow if needed */
}

/* Custom transition for smooth sliding from right */
.dialog-right-transition-enter-active,
.dialog-right-transition-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dialog-right-transition-enter {
  opacity: 0;
  transform: translateX(100%);
}

.dialog-right-transition-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

</style>
