import { render, staticRenderFns } from "./TransactionMatchingConfig.vue?vue&type=template&id=4b790c9a&scoped=true&"
import script from "./TransactionMatchingConfig.vue?vue&type=script&lang=js&"
export * from "./TransactionMatchingConfig.vue?vue&type=script&lang=js&"
import style0 from "./TransactionMatchingConfig.vue?vue&type=style&index=0&id=4b790c9a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b790c9a",
  null
  
)

export default component.exports